<div [ngClass]="isSecondUnit? 'secondary-color':'first-color'" class="list-entry">
  <div [ngClass]="isSelectedStructure()? 'selected-child-unit':!schema.isActive? 'inactive-element-stronger' : '' "
       class="flex-nowrap">
    <button (click)="selectStructure()" [ngClass]="!schema.isActive? 'font-italic' : '' "
            [routerLink]="['./departments/'+schema.id] " class="entry-title flex-nowrap" mat-button>
      <div class="flex-nowrap">
        <mat-icon *ngIf="isUnitSubstructure()" class="icon-spacer"
                  matTooltip="{{ 'navigation-list-entry.tooltip.is-substructure' | translate }}">
          account_tree
        </mat-icon>
        <mat-icon *ngIf="isUnitTeam()" class="icon-spacer"
                  matTooltip="{{ 'navigation-list-entry.tooltip.is-team' | translate }}">
          supervisor_account
        </mat-icon>
        <div class="unit-name overflow-ellipsis">{{schema.name}}
        </div>
        <div class="fill-space"></div>
        <mat-icon *ngIf="isManagerOfUnit()" class="icon-spacer"
                  matTooltip="{{ 'navigation-list-entry.tooltip.you-are-manager' | translate }}">
          person_outline
        </mat-icon>
        <mat-icon *ngIf="isMemberOfUnit()" class="icon-spacer"
                  matTooltip="{{ 'navigation-list-entry.tooltip.you-are-member' | translate }}">
          people_outline
        </mat-icon>
        <button (click)="$event.stopPropagation();toggleOpen()" *ngIf="hasChildUnits()" mat-icon-button>
          <mat-icon *ngIf="isOpen">expand_less</mat-icon>
          <mat-icon *ngIf="!isOpen">expand_more</mat-icon>
        </button>
      </div>
    </button>
  </div>
  <div *ngIf="isOpen && hasChildUnits()" class="list-subentries">
    <app-navigation-list-entry *ngFor="let departmentSchema of schema.subDepartments" [isSecondUnit]="!isSecondUnit"
                               [schema]="departmentSchema"></app-navigation-list-entry>
  </div>
</div>
