<app-dialog-component (okEmitter)="saveKeyResult()" [formGroup]="keyResultForm" [hasFormGroupError]="true"
                      [title]="title">
  <form [formGroup]="keyResultForm">
    <mat-form-field class="keyresult">
      <input formControlName="keyResult" matInput placeholder="{{ 'key-result-form.key-result' | translate }}">
      <mat-error>
        <app-form-error [control]="keyResultForm.get('keyResult')"></app-form-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="number-input">
      <input formControlName="start" matInput min="0" placeholder="{{ 'key-result-form.start' | translate }}"
             type="number">
      <mat-error>
        <app-form-error [control]="keyResultForm.get('start')"></app-form-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="number-input">
      <input formControlName="end" matInput min="0" placeholder="{{ 'key-result-form.target' | translate }}"
             type="number">
      <mat-error>
        <app-form-error [control]="keyResultForm.get('end')"></app-form-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="number-input">
      <input formControlName="current" matInput min="0" placeholder="{{ 'key-result-form.current' | translate }}"
             type="number">
      <mat-error>
        <app-form-error [control]="keyResultForm.get('current')"></app-form-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="number-input">
      <mat-select [formControlName]="'unit'" placeholder="{{ 'key-result-form.unit' | translate }}">
        <mat-option *ngFor="let unitKey of getViewUnitKeys()" [value]="unitEnum[unitKey]">
          {{getViewUnit(unitKey)}}
        </mat-option>
      </mat-select>
      <mat-error>
        <app-form-error [control]="keyResultForm.get('unit')"></app-form-error>
      </mat-error>
    </mat-form-field>

    <app-key-result-milestone-form [end]="keyResultForm.get('end').value"
                                   [formArray]="keyResultForm.get('viewKeyResultMilestones')" [keyResult]="keyResult"
                                   [start]="keyResultForm.get('start').value"></app-key-result-milestone-form>

    <mat-form-field class="fullWidth">
      <textarea formControlName="description" matInput matTextareaAutosize
                placeholder="{{ 'key-result-form.description' | translate }}"></textarea>
      <mat-error>
        <app-form-error [control]="keyResultForm.get('description')"></app-form-error>
      </mat-error>
    </mat-form-field>

  </form>
</app-dialog-component>
