<mat-expansion-panel (closed)="isPanelOpen = false" (opened)="isPanelOpen = true" [hideToggle]="true"
                     class="margin-expansion-panel">
  <!-- Objective Header -->
  <mat-expansion-panel-header [collapsedHeight]="'100%'" [expandedHeight]="'100%'" class="no-padding">
    <div [ngClass]="{'objective-inactive': !objective.isActive}" class="header-padding flex-nowrap f-1">
      <!-- Drag handle by parediv contained here -->
      <ng-content></ng-content>

      <div class="icon-spacer objective-title objective-lineNumber">{{ listNumber }} .</div>

      <div class="flex-wrap-spreadinner f-1">
        <div class="objective-title objective-title-flex">
          {{ objective.name }}
        </div>

        <!-- Progress bar if objective is active and has keyResults -->
        <div *ngIf="objective.isActive && objective.keyResultIdList.length > 0" class="objective-progressBar">
          <!-- Progress bar if KeyResults have been loaded in-->
          <div *ngIf="isProgressValueSetForObjective(); else progressNotSet" class="flex-nowrap-center progress-bar">
            <div [ngSwitch]="currentObjectiveScore.toString()" class="flex-nowrap-center f-1 hidden-m-down">
              <div *ngSwitchCase="2" class="circle off-track">
                <mat-icon matTooltip="{{ 'objective.tooltip.bad-progress' | translate }}">trending_down</mat-icon>
              </div>
              <div *ngSwitchCase="1" class="circle in-danger">
                <mat-icon matTooltip="{{ 'objective.tooltip.progress-can-be-better' | translate }}">trending_flat
                </mat-icon>
              </div>
              <div *ngSwitchDefault class="circle on-track">
                <mat-icon matTooltip="{{ 'objective.tooltip.good-progress' | translate }}">trending_up</mat-icon>
              </div>
            </div>
            <mat-progress-bar [value]="getProgressValueForObjective()" class="f-5 h-6 hidden-m-down" color="accent"
                              mode="determinate"></mat-progress-bar>
            <div class="text-center f-15">{{getProgressValueForObjective()}} %</div>
          </div>
          <!-- Progress bar if KeyResults haven't loaded in yet-->
          <ng-template #progressNotSet>
            <div class="flex-nowrap progress-bar">
              <div class="f-1"></div>
              <mat-progress-bar class="progress-bar f-5 h-6" color="accent" mode="indeterminate"></mat-progress-bar>
              <div class="f-15"></div>
            </div>
          </ng-template>
        </div>

        <!-- Flavor text when objective is inactive, displayed instead of progress bar-->
        <div *ngIf="!objective.isActive" class="flex-nowrap-center objective-progressBar">
          <mat-icon>info</mat-icon>
          <span>{{ 'objective.objective-isnt-active' | translate }}</span>
        </div>
      </div>

      <div *ngIf="hasSubObjectives()" class="icon-spacer-large f-05"
           matTooltip="{{ 'objective.tooltip.subordinate.' | translate }}">
        <button (click)="$event.stopPropagation()" [matMenuTriggerData]="{ objective: objective }"
                [matMenuTriggerFor]="childMenu" class="icon-spacer" mat-icon-button>
          {{ objective.subObjectivesCount }}
          <mat-icon>assignment</mat-icon>
        </button>
      </div>

      <button (click)="$event.stopPropagation()" *ngIf="!cycle.isCycleClosed()"
              [matMenuTriggerData]="{ objective: objective }" [matMenuTriggerFor]="optionsMenu" class="icon-spacer"
              mat-icon-button matTooltip="Mehr Optionen zu diesem Objective">
        <mat-icon>more_vert</mat-icon>
      </button>

      <button class="icon-spacer" mat-icon-button matTooltip="{{ 'objective.tooltip.expand-objective' | translate }}">
        <mat-icon *ngIf="!isPanelOpen">expand_more</mat-icon>
        <mat-icon *ngIf="isPanelOpen">expand_less</mat-icon>
      </button>
    </div>
  </mat-expansion-panel-header>
  <!-- Objective Contents -->
  <app-objective-contents #contentsComponent (objectiveProgressChanged)="updateObjectiveProgress($event)"
                          [currentUserRole]="currentUserRole" [cycle]="cycle"
                          [objective]="objective"></app-objective-contents>

</mat-expansion-panel>

<!-- Options menu -->
<mat-menu #optionsMenu="matMenu" xPosition="before">
  <ng-template let-objective="objective" matMenuContent>
    <button (click)="clickedEditObjective()" *ngIf="userIsOkrMember()" mat-menu-item>
      <mat-icon>edit</mat-icon>
      <span>{{ 'objective.menu.edit-objective' | translate }}</span>
    </button>
    <button (click)="clickedCommentObjective()" mat-menu-item>
      <mat-icon>chat</mat-icon>
      <span>{{ 'objective.menu.comment-objective' | translate }}</span>
    </button>
    <button (click)="toggleWhetherObjectiveIsActive()" *ngIf="userIsOkrMember()" mat-menu-item>
      <div *ngIf="objective.isActive">
        <mat-icon>power_off</mat-icon>
        <span>{{ 'objective.menu.disable-objective' | translate }}</span>
      </div>
      <div *ngIf="!objective.isActive">
        <mat-icon>power</mat-icon>
        <span>{{ 'objective.menu.enable-objective' | translate }}</span>
      </div>
    </button>
    <button (click)="clickedMoveObjectiveToTop()" *ngIf="!isObjectiveOnTop() && userIsOkrMember()" mat-menu-item>
      <mat-icon>vertical_align_top</mat-icon>
      <span>{{ 'objective.menu.move-up-objective' | translate }}</span>
    </button>
    <button (click)="clickedMoveObjectiveToBottom()" *ngIf="!isObjectiveOnBottom() && userIsOkrMember()" mat-menu-item>
      <mat-icon>vertical_align_bottom</mat-icon>
      <span>{{ 'objective.menu.move-down-objective' | translate }}</span>
    </button>
    <button (click)="clickedDeleteObjective()" *ngIf="currentUserRole.isAtleastOKRManager() && userIsOkrMember()"
            mat-menu-item>
      <mat-icon>delete</mat-icon>
      <span>{{ 'objective.menu.delete-objective' | translate }}</span>
    </button>
  </ng-template>
</mat-menu>

<!-- Child Menu -->
<mat-menu #childMenu="matMenu" xPosition="before">
  <div *ngFor="let child of childObjectives" [routerLink]="['/okr/departments/'+child.parentUnitId]"
       mat-menu-item>{{child.name}}</div>
</mat-menu>
