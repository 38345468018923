<form (capsLock)="capsLock=$event" [formGroup]="newPasswordForm" appCapsLock class="newPasswordForm">
  <mat-form-field *ngIf="previousPasswordNecessary" class="form-whole-line">
    <input formControlName="previousPassword" matInput placeholder="{{ 'password-form.previous-password' | translate }}"
           required type="password">
    <mat-error
      *ngIf="newPasswordForm.get('previousPassword').errors?.required">{{ 'password-form.error-message-previous-password-required' | translate }}</mat-error>
    <mat-error
      *ngIf="newPasswordForm.get('previousPassword').errors?.wrongPassword">{{ 'password-form.error-message-previous-password-wrong' | translate }}</mat-error>
  </mat-form-field>
  <mat-form-field class="form-whole-line">
    <input formControlName="newPassword" matInput placeholder="{{ 'password-form.new-password' | translate }}" required
           type="password">
  </mat-form-field>
  <mat-form-field class="form-whole-line">
    <input formControlName="newPasswordRepetition" matInput
           placeholder="{{ 'password-form.new-password-repetition' | translate }}" required type="password">
  </mat-form-field>
  <mat-error *ngIf="newPasswordForm.get('newPassword').valid
                 && newPasswordForm.invalid"
             class="error-span">{{ 'password-form.passwords-do-not-match' | translate }}</mat-error>
  <mat-error *ngIf="newPasswordForm.get('newPassword').invalid"
             class="error-span">{{ 'password-form.new-password-invalid' | translate }}</mat-error>
  <mat-error *ngIf="capsLock" class="error-span">{{ 'password-form.caps-lock-on' | translate }}</mat-error>
</form>


