<mat-card class="keyresult-card">
  <div class="flex-nowrap">

    <!-- Drag handle by parent component contained here -->
    <ng-content></ng-content>

    <div class="icon-spacer keyresult-title changePositonListNumber">{{ listNumber }} .</div>

    <div class="customFlex-1">

      <div class="flex-wrap">
        <div class="customFlex-2">
          <div class="keyresult-title">
            {{ keyResult.keyResult }}
          </div>
          <div class="keyresult-description">
            {{keyResult.description}}
          </div>
        </div>
        <div class="flex-nowrap keyresult-slider">
          <div class="f-5">
            <app-milestone-slider-wrapper [keyResult]="keyResult">
              <mat-slider (change)="onKeyResultSliderDropped($event)"
                          *ngIf="isKeyResultSliderInverted; else invertedKeyResultSlider"
                          [disabled]="!(currentUserRole.isAtleastOKRMember() && !cycle.isCycleClosed())"
                          [matTooltipDisabled]="!currentUserRole.isAdmin" [matTooltipShowDelay]="500" class="fullWidth"
                          matTooltip="{{ 'keyresult.tooltip.adjust-progress' | translate }}" max="{{ keyResult.end }}"
                          min="{{ keyResult.start }}" thumbLabel value="{{ keyResult.current }}"></mat-slider>
              <ng-template #invertedKeyResultSlider>
                <mat-slider (change)="onKeyResultSliderDropped($event)"
                            [disabled]="!(currentUserRole.isAtleastOKRMember() && !cycle.isCycleClosed())"
                            [invert]="true" [matTooltipDisabled]="!currentUserRole.isAdmin" [matTooltipShowDelay]="500"
                            class="fullWidth" matTooltip="{{ 'keyresult.tooltip.adjust-progress' | translate }}"
                            max="{{ keyResult.start }}" min="{{ keyResult.end }}" thumbLabel
                            value="{{ keyResult.current }}"></mat-slider>
              </ng-template>
            </app-milestone-slider-wrapper>
          </div>
          <div class="keyresult-progress-text f-1">
            {{getKeyResultProgressText()}}
          </div>
        </div>
      </div>
    </div>

    <button *ngIf="currentUserRole.isAtleastOKRMember() && !cycle.isCycleClosed()"
            [matMenuTriggerData]="{ keyResult: keyResult }" [matMenuTriggerFor]="optionsMenu"
            class="icon-spacer changePosition" mat-icon-button>
      <mat-icon matTooltip="{{ 'keyresult.tooltip.more-options' | translate }}">
        more_vert
      </mat-icon>
    </button>

    <button (click)="clickedOpenComments()" class="icon-spacer changePosition" mat-icon-button>
      <mat-icon [matBadge]="getKeyResultCommentCount()"
                matTooltip="{{ 'keyresult.tooltip.comments-for-this-key-result' | translate }}">chat
      </mat-icon>
    </button>

  </div>
</mat-card>

<!-- Options menu -->
<mat-menu #optionsMenu="matMenu" xPosition="before">
  <ng-template let-keyResult="keyResult" matMenuContent>
    <button (click)="clickedEditKeyResult()" mat-menu-item>
      <mat-icon>edit</mat-icon>
      <span>{{ 'keyresult.menu.edit-key-result' | translate }}</span>
    </button>
    <button (click)="clickedMoveKeyResultToTop()" *ngIf="!isKeyResultOnTop()" mat-menu-item>
      <mat-icon>vertical_align_top</mat-icon>
      <span>{{ 'keyresult.menu.move-key-result-up' | translate }}</span>
    </button>
    <button (click)="clickedMoveKeyResultToBottom()" *ngIf="!isKeyResultOnBottom()" mat-menu-item>
      <mat-icon>vertical_align_bottom</mat-icon>
      <span>{{ 'keyresult.menu.move-key-result-down' | translate }}</span>
    </button>
    <button (click)="clickedDeleteKeyResult()" *ngIf="currentUserRole.isAtleastOKRManager()" mat-menu-item>
      <mat-icon>delete</mat-icon>
      <span>{{ 'keyresult.menu.keyresult' | translate }}</span>
    </button>
  </ng-template>
</mat-menu>
