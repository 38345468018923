<p class="chart-title">{{'chart-options-card.title' | translate}} {{chart.title}}</p>
<p>{{'chart-options-card.chartType' | translate}} {{chartTypeRecord[chart.chartType] | translate}}</p>

<div *ngIf="allTeams$ | async; let teams">
  {{'chart-options-card.teams' | translate}}
  <p *ngFor="let team of getTeamsOfChart(teams)" class="chart-team-list">
    {{team.name}}</p>
  <p *ngIf="!chart.teamIds.length" class="chart-team-list">
    {{'chart-options-card.company-average' | translate}}
  </p>
</div>

<button (click)="clickedDelete.next(chart)" color="primary" mat-icon-button mat-mini-fab>
  <mat-icon>delete</mat-icon>
</button>
