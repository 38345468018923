<mat-card class="user-card">
  <div class="d-flex center f-1">
    <div class="flex-center f-1">
      <mat-icon class="icon-centered">person_add</mat-icon>
    </div>
    <div class="f-4">
      <app-user-autocomplete-input #inputForm (choseUser)="autoCompleteChoseUser($event)"
                                   [placeHolderText]="inputPlaceholderText"></app-user-autocomplete-input>
    </div>
  </div>
</mat-card>
