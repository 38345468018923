<app-okr-toolbar>
  <button [routerLink]="['/companies']" class="navbar-item" mat-icon-button>
    <mat-icon>home</mat-icon>
  </button>
  <span>{{'dashboard.okr-toolbar.dashboard' | translate}}</span>
  <span class="fill-space"></span>
</app-okr-toolbar>

<app-additional-info-bar [text]="'dashboard-overview.wip-info-text' | translate"></app-additional-info-bar>

<div *ngIf="dashboard$ | async; let dashboard">
  <span class="dashboard-title">
    {{dashboard.title}}
  </span>
  <div class="dashboard-charts">
    <div *ngFor="let chart of dashboard.charts">
      <app-chart-renderer [chartOptions]="chart"></app-chart-renderer>
    </div>
  </div>
</div>
