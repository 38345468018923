<div class="app-container footer-margin">
  <app-okr-toolbar>
    <span>{{'okr-unit-dashboard.okr-toolbar.unit-overview' | translate}}</span>
  </app-okr-toolbar>

  <!-- General Buttons -->
  <div class="margin-toolbar item-actions">
    <app-add-button (buttonClicked)="addCompany()" [isDisabled]="(isCurrentUserAdmin$ | async) === false"
                    text="{{'okr-unit-dashboard.add-unit' | translate}}"></app-add-button>
  </div>

  <!-- A card for each company -->
  <div *ngIf="companies$ | async as companies; else loading">
    <div *ngFor="let company of companies">
      <app-okr-unit-card (companyDeleted)="updateCompanies()" (newCycleStarted)="updateCompanies()"
                         [company]="company"></app-okr-unit-card>
    </div>
  </div>
  <ng-template #loading>
    <app-loading-spinner class="center center"></app-loading-spinner>
  </ng-template>
</div>
