<ng-container *ngIf="!cycleClosed && userRole.isAtleastAdmin()">
  <app-add-button [matMenuTriggerFor]="unitTypeDropdown"
                  text="{{'add-child-unit-button.add-new-child-unit' | translate}}"></app-add-button>
  <mat-menu #unitTypeDropdown="matMenu">
    <button (click)="clickedAddSubDepartment.emit()" mat-menu-item>
      <mat-icon>group_add</mat-icon>
      <span>{{'add-child-unit-button.okr-team' | translate}}</span>
    </button>
    <button (click)="clickedAddSubBranch.emit()" mat-menu-item>
      <mat-icon>add</mat-icon>
      <span>{{'add-child-unit-button.substructure' | translate}}</span>
    </button>
  </mat-menu>
</ng-container>
