<app-dialog-component (okEmitter)="clickedDone()" [saveAndCloseLabel]="saveAndCloseLabel$ | async"
                      [title]="title$ | async">
  <mat-form-field>
    <mat-select-trigger>
      <small>{{'language-picker.select-trigger-text' | translate}}</small> <br>
    </mat-select-trigger>
    <mat-select (selectionChange)="languageChanged($event)" [value]="this.translate.currentLang" id="language-select">
      <mat-option value="en">
        {{'language-picker.language.english' | translate}}
      </mat-option>
      <mat-option value="de">
        {{'language-picker.language.german' | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</app-dialog-component>


