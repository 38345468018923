<div class="comment">
  <div class="flex-nowrap-center">
    <ngx-avatars [name]="comment.userName"
                 [src]="comment.photo ? 'data:image/jpg;base64,' + comment.photo : ''"></ngx-avatars>
  </div>
  <div class="ml-10">
    <div class="flex-nowrap comment-user-name">
      <div class="fill-space"><b>
        {{comment.userName}}</b> <small class="ml-10">{{comment.date | dateFormat | async}}</small>
      </div>

      <div *ngIf="!isEditingComment && isOwnerOfNote$ | async">
        <button (click)="clickedEditComment()" class="icon-spacer" mat-icon-button
                matTooltip="{{'comment-card.tooltip.edit-comment' | translate}}">
          <mat-icon>edit</mat-icon>
        </button>

        <button (click)="clickedDeleteComment()" class="icon-spacer" mat-icon-button
                matTooltip="{{'comment-card.tooltip.delete-comment' | translate}}">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <div *ngIf="isEditingComment && isOwnerOfNote$ | async">
        <button (click)="clickedSaveComment()"
                [disabled]="isSavingEdit || this.editedCommentText.length < 3 || this.editedCommentText.length > 255"
                class="icon-spacer" mat-icon-button matTooltip="{{'comment-card.tooltip.save-comment' | translate}}">
          <mat-icon>save</mat-icon>
        </button>
        <button (click)="clickedCancelEditComment()" [disabled]="isSavingEdit" class="icon-spacer" mat-icon-button
                matTooltip="{{'comment-card.tooltip.cancel' | translate}}">
          <mat-icon>cancel</mat-icon>
        </button>
      </div>

    </div>
    <div *ngIf="!isEditingComment" class="comment-text">
      {{comment.text}}
    </div>
    <div *ngIf="isEditingComment">
      <div class="flex-nowrap">
        <mat-form-field class="full-width">
          <textarea [(ngModel)]="editedCommentText" [disabled]="isSavingEdit" cdkTextareaAutosize matInput
                    placeholder="{{'comment-card.edit-comment' | translate}}"></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>

</div>
