<mat-slide-toggle (change)="isChecked=$event.checked" [checked]="isChecked" [disabled]="formArray?.length > 0"
                  class="toggleMilestone">{{ 'key-result-milestone-form.toggle' | translate }}</mat-slide-toggle>

<div *ngIf="isChecked" class="keyresult-milestone-form-wrapper">
  <div *ngFor="let formGroup of formArray.controls" class="inputs">
    <mat-form-field class="text-input">
      <input [formControl]="formGroup.get('name')" matInput
             placeholder="{{ 'key-result-milestone-form.name-*' | translate }}" type="text">
      <mat-error>
        <app-form-error [control]="formGroup.get('name')"></app-form-error>
      </mat-error>
    </mat-form-field>


    <mat-form-field class="number-input">
      <input [formControl]="formGroup.get('value')" matInput
             placeholder="{{ 'key-result-milestone-form.value-input' | translate }}" type="number">
      <mat-error>
        <app-form-error [control]="formGroup.get('value')"></app-form-error>
      </mat-error>
    </mat-form-field>


    <button (click)="deleteMilestone(formGroup)" class="delete-button" mat-icon-button
            matTooltip="{{ 'key-result-milestone-form.tooltip.delete-button' | translate }}">
      <mat-icon>
        delete
      </mat-icon>
    </button>
  </div>
</div>
<app-add-button (buttonClicked)="addMilestone(undefined, undefined,undefined, undefined)" *ngIf="isChecked"
                text="{{ 'key-result-milestone-form.add-button' | translate }}"></app-add-button>
