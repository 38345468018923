<h1>{{ 'demo-warning.title' | translate }}</h1>
<mat-dialog-content>
  <p>{{ 'demo-warning.explanation' | translate }}</p>

  <div class="info-container">
    <div class="icon-container">
      <mat-icon class="icon-fix">emoji_symbols</mat-icon>
    </div>
    <div class="icon-description-container">
      <h4>{{ 'demo-warning.user-generated-title' | translate }}</h4>
      <p>{{ 'demo-warning.user-generated-text' | translate }}</p>
    </div>
  </div>

  <div class="info-container">
    <div class="icon-container">
      <mat-icon class="icon-fix">favorite</mat-icon>
    </div>
    <div class="icon-description-container">
      <h4>{{'demo-warning.good-vibes-only-title' | translate}}</h4>
      <p>{{'demo-warning.good-vibes-only-text' | translate}}</p>
    </div>
  </div>

  <div class="info-container">
    <div class="icon-container">
      <mat-icon class="icon-fix">access_time</mat-icon>
    </div>
    <div class="icon-description-container">
      <h4>{{'demo-warning.database-deletion-title' | translate}}</h4>
      <p>{{'demo-warning.database-deletion-text' | translate}}</p>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button (click)="redirectToPlayground()" color="primary"
          mat-button>{{ 'demo-warning.playground-button' | translate }}</button>
  <button (click)="abort()" color="primary" mat-button>{{ 'demo-warning.abort-button' | translate }}</button>
</mat-dialog-actions>
