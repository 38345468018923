<app-okr-toolbar>
  <button (click)="navigateToCompanies()" class="navbar-item" mat-icon-button
          matTooltip="{{ 'admin-view.tooltip.go-to-okr-unit-overview' | translate }}">
    <mat-icon>home</mat-icon>
  </button>
  <span>{{ 'admin-view.admin-panel' | translate }}</span>
</app-okr-toolbar>

<div class="main-div">
  <mat-card class="center">
    <mat-card-title>
      <mat-icon>group</mat-icon>
      <span>{{ 'admin-view.admins' | translate }}</span>
    </mat-card-title>

    <mat-card-content *ngIf="adminUsers$ | async as adminUsers">
      <p *ngIf="adminUsers.length === 0">
        <mat-spinner class="center center"></mat-spinner>
      </p>

      <p *ngIf="adminUsers.length !== 0">
        <mat-list>
          <mat-list-item *ngFor="let admin of adminUsers; trackBy: ('id' | trackByProperty)" class="list-items">
            <mat-icon class="list-left">person</mat-icon>
            <span class="list-center">{{ admin.surname }}, {{ admin.givenName }}</span>
            <ng-container *ngIf="currentUserId$ | async as currentUserId" class="list-right">
              <button (click)="onDeleteAdminButtonClicked(admin)" [disabled]="admin.id === currentUserId"
                      mat-icon-button>
                <mat-icon *ngIf="admin.id !== currentUserId; else deleteSelf"
                          matTooltip="{{'admin-view.icon-tooltip' | translate: {adminName: admin.givenName} }}">delete
                </mat-icon>
                <ng-template #deleteSelf>
                  <mat-icon matTooltip="{{ 'admin-view.tooltip.cannot-delete-yourself' | translate }}">delete
                  </mat-icon>
                </ng-template>
              </button>
            </ng-container>
            <mat-divider [inset]="true"></mat-divider>
          </mat-list-item>
          <mat-list-item>
            <mat-icon class="list-left">person_add</mat-icon>
            <app-user-autocomplete-input #newAdminForm (choseUser)="defineNewAdmin($event)" [disabled]="isPlayground"
                                         [userIdsToExclude]="adminUsers | adminUserIds:excludedIdsShouldUpdate"
                                         class="list-center"
                                         placeHolderText="{{'admin-view.placeholder' | translate}}"></app-user-autocomplete-input>
            <span *ngIf="isPlayground">{{ 'disabled-in-demo-version' | translate }}</span>
          </mat-list-item>
        </mat-list>
      </p>
    </mat-card-content>
  </mat-card>
</div>
