<ng-container *ngIf="feedbackForm$ | async as feedbackForm">
  <ng-container *ngIf="noCurrentRequest; else loadingspinner">
    <app-dialog-component (okEmitter)="sendFeedback($event)" [formGroup]="feedbackForm"
                          saveAndCloseLabel="{{'feedback-form.dialog.save-button' | translate}}"
                          title="{{'feedback-form.dialog.title' | translate}}">
      <form [formGroup]="feedbackForm">
        <div>
          <mat-form-field class="name fullWidth">
            <input formControlName="name" matInput placeholder="{{'feedback-form.form-field.name' | translate}} *"
                   type="text">
            <mat-error>
              <app-form-error [control]="feedbackForm.get('name')"></app-form-error>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="feedbackText fullWidth">
            <textarea formControlName="feedbackText" matInput
                      placeholder="{{'feedback-form.form-field.feedback' | translate}} *" type="text"></textarea>
            <mat-error>
              <app-form-error [control]="feedbackForm.get('feedbackText')"></app-form-error>
            </mat-error>
          </mat-form-field>
        </div>
      </form>
    </app-dialog-component>
  </ng-container>
  <ng-template #loadingspinner>
    <app-loading-spinner></app-loading-spinner>
  </ng-template>
</ng-container>
