<ng-template [ngIfElse]="demoOff" [ngIf]="isPlayground">
  <div class="scroll-to-top show-scrollTop">
    <button (click)="navigateToGitHub()" class="color-white" mat-fab
            matTooltip="{{'feedback-button.button-icon.tooltip' | translate}}">
      <mat-icon>
        report_problem
      </mat-icon>
    </button>
  </div>
</ng-template>
<ng-template #demoOff>
  <div class="fab-container">
    <button (click)="openFeedbackPopup()" [disabled]="(hasMail$ | async) === false" mat-fab
            matTooltip="{{'feedback-button.button-icon.tooltip' | translate}}">
      <mat-icon>
        contact_mail
      </mat-icon>
    </button>
  </div>
</ng-template>

