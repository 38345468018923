<div *ngIf="keyResultList; else loading">
  <!-- Parent objective button -->
  <div *ngIf="parentObjective" class="flex-nowrap-center">
    <button [routerLink]="['../', parentObjective.parentUnitId]" class="parent-objective" mat-stroked-button
            matTooltip="{{ 'objective-contents.tooltip.navigate-to-unit' | translate }}">
      <span>{{ 'objective-contents.parent-objective' | translate }}</span>
      <span class="font-w-400"> {{parentObjective.name}}</span>
    </button>
  </div>

  <!-- Title and add new KeyResult button -->
  <div class="flex-nowrap">
    <div class="f-3">
      <div class="tab-subtitle">KeyResults</div>
    </div>
  </div>

  <!-- No KeyResults placeholder-->
  <div *ngIf="keyResultList.length === 0">
    <mat-card class="no-key-result">{{ 'objective-contents.no-key-result-found' | translate }}</mat-card>
  </div>

  <!-- List of KeyResults -->
  <div (cdkDropListDropped)="dropKeyResult($event)"
       [cdkDropListDisabled]="!(currentUserRole.isAtleastOKRMember() && !cycle.isCycleClosed())" cdkDropList>
    <div *ngFor="let keyResult of keyResultList; let i = index" cdkDrag>
      <app-keyresult (keyResultProgressChanged)="updateVisualKeyResultProgressTotals()"
                     (moveKeyResultToBottom)="moveKeyResultToBottom($event)"
                     (moveKeyResultToTop)="moveKeyResultToTop($event)" [currentUserRole]="currentUserRole"
                     [cycle]="cycle" [keyResultList]="keyResultList" [keyResult]="keyResult" [listNumber]="i+1"
                     [objective]="objective">
        <div *ngIf="currentUserRole.isAtleastOKRMember() && !cycle.isCycleClosed()" class="icon-spacer">
          <mat-icon cdkDragHandle>drag_indicator</mat-icon>
        </div>
      </app-keyresult>
    </div>
  </div>
  <div *ngIf="currentUserRole.isAtleastOKRMember() && !cycle.isCycleClosed()" class="f-1">
    <app-add-button (buttonClicked)="clickedAddKeyResult()" [isDisabled]="maximumKeyResultsReached()"
                    id="key-result-button"
                    text="{{ 'objective-contents.define-new-key-result' | translate }}"></app-add-button>
  </div>
</div>

<!-- Loading placeholder -->
<ng-template #loading>
  <div class="text-center">
    <h3>{{ 'objective-contents.key-results' | translate }}</h3>
  </div>
  <mat-card *ngFor="let placeholder of objective.keyResultIdList">
    <span>{{ 'objective-contents.load-key-results' | translate }}</span>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    <br>
  </mat-card>
</ng-template>
