import { Component } from '@angular/core';

@Component({
  selector: 'app-demo-imprint',
  templateUrl: './demo-imprint.component.html',
  styleUrls: ['./demo-imprint.component.scss'],
})
export class DemoImprintComponent {

}
