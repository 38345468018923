<div *ngIf="childUnit$ | async as childUnit; else loading">
  <button [ngClass]="{ 'inactive-element': !childUnit.isActive }" [routerLink]="['../../departments', unitId]"
          class="previewButton" mat-raised-button
          matTooltip="{{ 'okr-child-unit-preview-button.tooltip.show-department' | translate }}">
    <div class="d-flex center">
      <div class="f-1">
        <mat-icon class="font-32">{{isOkrBranch(childUnit) ? 'account_tree' : 'group'}}</mat-icon>
      </div>
      <div class="f-5 overflow-ellipsis">
        {{ childUnit.label }}
        <br>
        {{ childUnit.name }}
        <div class="d-flex">
          <div class="f-1">
            <mat-icon matTooltip="{{ 'okr-child-unit-preview-button.tooltip.list-objectives' | translate }}">list
            </mat-icon>
            {{ childUnit.objectives.length }}
          </div>
          <div *ngIf="isOkrBranch(childUnit)" class="f-1">
            <mat-icon matTooltip="{{ 'okr-child-unit-preview-button.tooltip.list-child-unit' | translate }}">
              low_priority
            </mat-icon>
            <!-- IDEs will show an error at okrChildUnitIds, because it is not a member of OkrChildUnit -->
            <!-- But it is safe to access okrChildUnitIds here, because we check that the childUnit is an OkrBranch before. -->
            {{ childUnit.okrChildUnitIds.length }}
          </div>
        </div>
      </div>
      <div *ngIf="!childUnit.isActive" class="f-05">
        <mat-icon matTooltip="{{ 'okr-child-unit-preview-button.tooltip.not-active' | translate }}">info</mat-icon>
      </div>
    </div>
  </button>
</div>

<!-- Placeholder for loading -->
<ng-template #loading>
  <mat-card class="previewButton">
    <p>{{ 'okr-child-unit-preview-button.load' | translate }}</p>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </mat-card>
</ng-template>
