<div class="container">
  <div class="main-image-container">
    <img alt="burning-okr logo" class="main-image" src="../../../assets/logo.png">
  </div>

  <div class="main-button-container-flex main-button-container">

    <a class="main-button button-bag-layout-inverted" href="https://github.com/BurningOKR/BurningOKR" mat-stroked-button
       rel="noopener noreferrer" target="_blank">{{ 'demo-home.github' | translate }}</a>

    <span class="line-break hidden-s-up"></span>

    <button [matMenuTriggerFor]="documentationMenu" class="main-button button-bag-layout-inverted" mat-stroked-button>
      {{'demo-home.documentation'| translate}}
    </button>

    <span class="line-break hidden-s-up"></span>

    <button (click)="openPlayground()" class="main-button button-bag-layout-inverted"
            mat-stroked-button>{{ 'demo-home.playground' | translate }}</button>

  </div>

  <div class="main-content col-12">

    <div class="wow animate__fadeIn col-m-7 col-s-12" data-wow-duration="1s">
      <h1 class="headline">{{'demo-home.welcome.title' | translate}}</h1>
      <iframe allowfullscreen data-name="youtube" data-src="https://www.youtube.com/embed/Lw_-Y4B8ad0" height="315"
              title="YouTube video player" width="560"></iframe>
      <p>
        {{'demo-home.welcome.content'| translate}}
      </p>
    </div>

    <div class="wow animate__fadeIn content-image-container" data-wow-duration="1s">
      <img alt="" class="col-m-5 col-s-12 content-image" src="../../../assets/okr-stocks/OKR_zielscheibe.svg">
    </div>

  </div>

  <div class="main-content col-12">

    <div class="wow animate__fadeIn content-image-container" data-wow-duration="1s">
      <img alt="" class="col-m-5 col-s-12 content-image" src="../../../assets/okr-stocks/OKR_stonks.svg">
    </div>

    <div class="wow animate__fadeIn col-m-7 col-s-12" data-wow-duration="1s">
      <h2 class="headline">{{'demo-home.visions.title' | translate}}</h2>
      <p>
        {{'demo-home.visions.content'| translate}}
      </p>
    </div>

  </div>

  <div class="main-content col-12">

    <div class="wow animate__fadeIn col-m-7 col-s-12" data-wow-duration="1s">
      <h2 class="headline">{{'demo-home.digitalization.title' | translate}}</h2>
      <p>
        {{'demo-home.digitalization.content' | translate}}
      </p>
    </div>

    <div class="wow animate__fadeIn content-image-container" data-wow-duration="1s">
      <img alt="" class="col-m-5 col-s-12 content-image" src="../../../assets/okr-stocks/OKR_digital.svg">
    </div>

  </div>


  <div class="main-content col-12">
    <div class="wow animate__fadeIn content-image-container" data-wow-duration="1s">
      <img alt="" class="col-m-5 col-s-12 content-image" src="../../../assets/okr-stocks/OKR_billboard.svg">
    </div>

    <div class="wow animate__fadeIn col-m-7 col-s-12" data-wow-duration="1s">
      <h2 class="headline">{{'demo-home.target-management.title' | translate}}</h2>
      <p>
        {{'demo-home.target-management.content' | translate}}
      </p>
    </div>

  </div>


  <div class="main-content col-12">
    <div class="wow animate__fadeIn col-m-7 col-s-12" data-wow-duration="1s">
      <h2 class="headline">{{'demo-home.features.title' | translate}}</h2>
      <p>
        {{'demo-home.features.content' | translate}}
      </p>
      <ul innerHTML="{{'demo-home.features.perks-list' | translate}}"></ul>
    </div>

    <div class="wow animate__fadeIn content-image-container" data-wow-duration="1s">
      <img alt="" class="col-m-5 col-s-12 content-image" src="../../../assets/okr-stocks/OKR_features.svg">
    </div>
  </div>


  <div class="main-content col-12">


    <div class="wow animate__fadeIn content-image-container" data-wow-duration="1s">
      <img alt="" class="col-m-5 col-s-12 content-image" src="../../../assets/okr-stocks/OKR_okr.svg">
    </div>

    <div class="wow animate__fadeIn col-m-7 col-s-12" data-wow-duration="1s">
      <h2 class="headline">{{'demo-home.installation.title' | translate}}</h2>
      <p>
        {{'demo-home.installation.content' | translate}}
      </p>
      <ul innerHTML="{{'demo-home.installation.list' | translate}}"></ul>
    </div>
  </div>


  <div class="main-content col-12">
    <div class="wow animate__fadeIn col-m-7 col-s-12" data-wow-duration="1s">
      <h2 class="headline">{{'demo-home.free-and-open-source.title' | translate}}</h2>
      <p innerHTML="{{'demo-home.free-and-open-source.content' | translate}}"></p>
    </div>

    <div class="wow animate__fadeIn content-image-container" data-wow-duration="1s">
      <img alt="" class="col-m-5 col-s-12 content-image" src="../../../assets/okr-stocks/OKR_trompete.svg">
    </div>
  </div>


  <div class="main-content col-12">
    <div class="wow animate__fadeIn content-image-container" data-wow-duration="1s">
      <img alt="" class="col-m-5 col-s-12 content-image" src="../../../assets/okr-stocks/OKR_kontakt.svg">
    </div>

    <div class="wow animate__fadeIn col-m-7 col-s-12" data-wow-duration="1s">
      <h2 class="headline">{{'demo-home.contact.title' | translate}}</h2>
      <p>
        {{'demo-home.contact.content' | translate}}
      </p>
      <p innerHTML="{{'demo-home.contact.content-with-link' | translate}}"></p>
    </div>

  </div>


  <div class="main-content col-12">
    <div class="wow animate__fadeIn" data-wow-duration="1s">
      <h2 class="headline">{{'demo-home.further-information.title' | translate}}</h2>
      <p innerHTML="{{'demo-home.further-information.content' | translate}}"></p>
    </div>
  </div>


  <div class="col-12 social-media-container footer-margin wow animate__fadeInUp" data-wow-duration="1s">
    <div class="social-media-link">
      <a href="https://github.com/BurningOKR/BurningOKR" rel="noopener noreferrer" target="_blank">
        <img alt="BurningOKR GitHub" class="image-link" id="github-icon"
             src="../../../assets/GitHub-Mark-120px-plus.png">
      </a>
    </div>

    <div class="line-break hidden-s-up"></div>

    <div class="social-media-link">
      <a href="http://twitter.com/BurningOkr" rel="noopener noreferrer" target="_blank">
        <img alt="BurningOKR Twitter" class="image-link" id="twitter-icon" src="../../../assets/Twitter_Logo_Blue.svg">
      </a>
    </div>

    <div class="line-break hidden-s-up"></div>

    <div class="social-media-link">
      <a href="mailto:burningokr@brockhaus-ag.de" rel="noopener noreferrer" target="_blank">
        <img alt="E-Mail" class="image-link" id="mail-icon" src="../../../assets/mail_outline-24px.svg">
      </a>
    </div>

  </div>
</div>


<mat-menu #documentationMenu>
  <a href="https://burning-okr.gitbook.io/burningokr/" mat-menu-item rel="noopener noreferrer" target="_blank">
    {{'language-picker.language.german' | translate}}
  </a>
  <a href="https://burning-okr.gitbook.io/burningokr/v/en/" mat-menu-item rel="noopener noreferrer" target="_blank">
    {{'language-picker.language.english' | translate}}
  </a>
</mat-menu>

