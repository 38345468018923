<div #footer class="footer-container padding-top-bottom">
  <div class="col-m-7 col-s-12 no-padding">
    <div>
      © Copyright 2020
      <a href="https://www.brockhaus-ag.de/" rel="noopener noreferrer" target="_blank">BROCKHAUS AG</a>
      - BurningOKR. All Rights Reserved
    </div>
  </div>

  <div class="col-m-5 col-s-12 no-padding">
    <a class="margin-left-right" routerLink="/demo/credits">{{ 'demo-footer.credits' | translate }}</a>
    <a class="margin-left-right" routerLink="/demo/privacy-policy">{{ 'demo-footer.privacy-policy' | translate }}</a>
    <a class="margin-left-right" routerLink="/demo/imprint">{{ 'demo-footer.imprint' | translate }}</a>
  </div>
</div>
