<div class="flex-nowrap-center">
  <ng-container *ngIf="!error404; else loadingError">
    <ng-container *ngIf="departmentView$ | async let info; else loading">
      <ng-container *ngIf="info.okrChildUnit as childUnit">
        <ng-container *ngIf="info.cycle as cycle">
          <ng-container *ngIf="info.currentUserRole as currentUserRole">
            <mat-card [ngClass]="{ 'inactive-element': !childUnit.isActive }"
                      class="small-padding card-margin card-size">

              <div class="flex-wrap inner-padding">
                <div class="icon-spacer-large">
                  <button (click)="moveToParentUnit(childUnit)" class="changePositionButton" mat-icon-button
                          matTooltip="Zu der übergeordneten Struktur">
                    <mat-icon>arrow_upward</mat-icon>
                  </button>
                </div>

                <div class="flex-wrap-spreadinner f-1 flex-department-title">
                  <div class="department-flex overflow-ellipsis">
                    <div class="department-title overflow-ellipsis">{{ childUnit.name }}</div>
                    <div class="department-subtitle overflow-ellipsis"> {{ childUnit.label }}</div>
                  </div>
                  <div *ngIf="!childUnit.isActive" class="flex-nowrap-center department-inactive-box">
                    <mat-icon>info</mat-icon>
                    <span>{{ 'okr-child-unit.unit-not-active' | translate }}</span>
                  </div>
                </div>
                <div *ngIf="currentUserRole.isAtleastAdmin() && !cycle.isCycleClosed()" class="icon-spacer-large">
                  <button [matMenuTriggerData]="{ childUnit: childUnit }" [matMenuTriggerFor]="optionsMenu"
                          mat-icon-button>
                    <mat-icon matTooltip="{{ 'okr-child-unit.tooltip.more-options' | translate }}">more_vert
                    </mat-icon>
                  </button>
                </div>
              </div>

              <br>
              <!-- Tabs -->
              <mat-tab-group *ngIf="activeTabs$ | async; let activeTabs" animationDuration="350ms">
                <!-- Overview Tab-->
                <mat-tab label="{{ 'okr-child-unit.overview' | translate }}">
                  <div class="inner-padding">
                    <app-okr-child-unit-overview-tab [currentUserRole]="currentUserRole" [cycle]="cycle"
                                                     [okrChildUnit]="childUnit"></app-okr-child-unit-overview-tab>
                  </div>
                </mat-tab>

                <!-- Members Tab-->
                <mat-tab *ngIf="activeTabs.teamsTab" label="{{ 'okr-child-unit.team-member' | translate }}">
                  <div class="inner-padding">
                    <app-department-tab-team [currentUserRole]="currentUserRole" [cycle]="cycle"
                                             [department]="childUnit"></app-department-tab-team>
                  </div>
                </mat-tab>

                <!-- Subdepartments Tab-->
                <mat-tab *ngIf="activeTabs.childUnitTab" label="{{ 'okr-child-unit.substructures' | translate }}">
                  <ng-template matTabContent>
                    <div class="inner-padding">
                      <app-okr-child-unit-tab [currentUserRole]="currentUserRole" [cycle]="cycle"
                                              [okrBranch]="childUnit"></app-okr-child-unit-tab>
                    </div>
                  </ng-template>
                </mat-tab>

                <!-- Description Tab-->
                <mat-tab *ngIf="activeTabs.descriptionTab" label="{{ 'okr-child-unit.description' | translate }}">
                  <ng-template matTabContent>
                    <div class="inner-padding">
                      <app-department-tab-description [currentUserRole]="currentUserRole"
                                                      [department]="childUnit"></app-department-tab-description>
                    </div>
                  </ng-template>
                </mat-tab>


                <!-- Tasks Tab -->
                <mat-tab *ngIf="activeTabs.teamsTab" label="{{ 'okr-child-unit.task-board-headline' | translate }}">
                  <ng-template matTabContent>
                    <div class="inner-padding">
                      <app-department-tab-taskboard [childUnit]="childUnit" [currentUserRole]="currentUserRole"
                                                    [cycle]="cycle">

                      </app-department-tab-taskboard>
                    </div>
                  </ng-template>
                </mat-tab>

              </mat-tab-group>
            </mat-card>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<!-- Options menu -->
<mat-menu #optionsMenu="matMenu" xPosition="before">
  <ng-template let-childUnit="childUnit" matMenuContent>
    <button (click)="clickedEditChildUnit(childUnit)" mat-menu-item>
      <mat-icon>edit</mat-icon>
      <span>{{ 'okr-child-unit.menu.editchild-unit' | translate }}</span>
    </button>
    <button (click)="toggleChildActive(childUnit)" mat-menu-item>
      <div *ngIf="childUnit.isActive">
        <mat-icon>power_off</mat-icon>
        <span>{{ 'okr-child-unit.menu.disable-unit' | translate }}</span>
      </div>
      <div *ngIf="!childUnit.isActive">
        <mat-icon>power</mat-icon>
        <span>{{ 'okr-child-unit.menu.enable-unit' | translate }}</span>
      </div>
    </button>
    <button (click)="downloadOkrChildUnitExcelFile(childUnit)" mat-menu-item>
      <mat-icon>save_alt</mat-icon>
      <span>{{ 'okr-child-unit.menu.export' | translate }}</span>
    </button>
    <button (click)="downloadDepartmentExcelEmailFile(childUnit)" *ngIf="isDepartmentUnit(childUnit)" mat-menu-item>
      <mat-icon>save_alt</mat-icon>
      <span>{{ 'okr-child-unit.menu.contacts' | translate }}</span>
    </button>
    <div *ngIf="canChildUnitBeRemoved(childUnit); else cannotDeleteUnit;">
      <button (click)="clickedRemoveChildUnit(childUnit)" [disabled]="!canChildUnitBeRemoved(childUnit)" mat-menu-item>
        <mat-icon>delete</mat-icon>
        <span>{{ 'okr-child-unit.menu.delete-unit' | translate }}</span>
      </button>
    </div>

    <ng-template #cannotDeleteUnit>
      <div matTooltip="{{ 'okr-child-unit.tooltip.cannot-delete-unit' | translate }}">
        <button [disabled]="true" mat-menu-item>
          <mat-icon>delete</mat-icon>
          <span>{{ 'okr-child-unit.menu.delete-unit' | translate }}</span>
        </button>
      </div>
    </ng-template>
  </ng-template>

</mat-menu>

<!-- Placeholder during loading of the okrChildUnit -->
<ng-template #loading2>
  <mat-card class="small-padding card-margin card-size">
    <div class="m-left">
      <div class="department-title">{{ 'okr-child-unit.load-department' | translate }}</div>
      <div class="department-subtitle">{{ 'okr-child-unit.please-wait' | translate }}</div>
    </div>
    <mat-tab-group>
      <mat-tab label="{{ 'okr-child-unit.overview' | translate }}">
        <div class="loading-content">
          <br>
          <mat-spinner></mat-spinner>
          <br>
        </div>
      </mat-tab>
      <mat-tab label="{{ 'okr-child-unit.team-member' | translate }}"></mat-tab>
      <mat-tab label="{{ 'okr-child-unit.substructures' | translate }}"></mat-tab>
      <mat-tab label="{{ 'okr-child-unit.task-board-headline' | translate }}"></mat-tab>
    </mat-tab-group>
  </mat-card>
</ng-template>


<ng-template #loading>
  <mat-card class="small-padding card-margin card-size">
    <div class="flex-wrap inner-padding">
      <div class="icon-spacer-large">
        <button disabled mat-icon-button matTooltip="Zu der übergeordneten Struktur">
          <mat-icon>arrow_upward</mat-icon>
        </button>
      </div>

      <div class="flex-wrap-spreadinner f-1">
        <div class="department-flex">
          <div class="department-title">{{ 'okr-child-unit.load-department' | translate }}</div>
          <div class="department-subtitle">{{ 'okr-child-unit.please-wait' | translate }}</div>
        </div>
      </div>
    </div>

    <br>

    <mat-tab-group>
      <mat-tab class="inner-padding" label="{{ 'okr-child-unit.overview' | translate }}">
        <div class="loading-content">
          <br>
          <mat-spinner></mat-spinner>
          <br>
        </div>
      </mat-tab>
      <mat-tab class="inner-padding" label="{{ 'okr-child-unit.loading' | translate }}"></mat-tab>
    </mat-tab-group>
  </mat-card>
</ng-template>

<ng-template #loadingError>
  <mat-card class="small-padding card-margin card-size">
    <div class="flex-wrap inner-padding">
      <div class="icon-spacer-large">
        <button disabled mat-icon-button matTooltip="Zu der übergeordneten Struktur">
          <mat-icon>arrow_upward</mat-icon>
        </button>
      </div>

      <div class="flex-wrap-spreadinner f-1">
        <div class="department-flex">
          <div class="department-title">{{ 'okr-child-unit.load-department-failed' | translate }}</div>
          <div class="department-subtitle">{{ 'okr-child-unit.empty-label' | translate }}</div>
        </div>
      </div>
    </div>

    <br>

    <mat-tab-group>
      <mat-tab class="inner-padding" label="{{ 'okr-child-unit.error-info-label' | translate }}">
        <div class="inner-padding">
          <p>{{ 'okr-child-unit.load-department-failed-info-text' | translate }}</p>
          <button (click)="moveToParentUnit404()" color="primary" mat-button
                  mat-raised-button>{{ 'okr-child-unit.load-department-failed-button' | translate }}</button>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card>
</ng-template>
