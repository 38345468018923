<div *ngIf="topicDrafts[0] !== undefined" class="card-wrapper">
  <div class="card-wrapper-head-wrapper">
    <div class="card-wrapper-head">
      <span class="card-wrapper-head-column-info">{{'topic-draft-list.column.submitted' | translate}}</span>
      <span class="card-wrapper-head-column-info">{{'topic-draft-list.column.start' | translate}}</span>
      <span class="card-wrapper-head-column-info">{{'topic-draft-list.column.name' | translate}}</span>
    </div>
  </div>
  <div *ngFor="let topicDraft of topicDrafts">
    <app-topic-draft-card (editedTopicDraftEvent)="updateEditedTopicDraft($event)"
                          (topicDraftDeletedEvent)="removeDeletedTopicDraft($event)"
                          [topicDraft]="topicDraft"></app-topic-draft-card>
  </div>
</div>
<div *ngIf="topicDrafts[0] === undefined" class="card-wrapper-info-area">
  <p>{{'topic-draft-list.no-new-topic-drafts' | translate}}</p>
</div>
