<mat-card *ngIf="user$ | async as user; else loading;" class="user-card">
  <div class="flex-center f-1 user-details">
    <div class="flex-center f-1 user-avatar">
      <app-user-avatar [user]="user"></app-user-avatar>
    </div>
    <div class="f-4 ml-10 user-infos">
      <div class="name">{{user.surname}}, {{user.givenName}}</div>
      <div class="subtitle">{{user.jobTitle}}</div>
      <div class="subtitle">{{user.department}}</div>
    </div>
    <div *ngIf="canBeRemoved" class="flex-center f-1">
      <button (click)="clickedDeleteButton()" class="delete-button" mat-icon-button
              matTooltip="{{ 'user-minibutton.tooltip.remove-member' | translate }}">
        <mat-icon class="icon-centered">delete</mat-icon>
      </button>
    </div>
  </div>
</mat-card><!-- Loading placeholder -->
<ng-template #loading>
  <mat-card class="user-card">
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon class="font-32">person</mat-icon>
      </div>
      <mat-card-title>{{ 'user-minibutton.loading-user' | translate }}</mat-card-title>
    </mat-card-header>
  </mat-card>
</ng-template>
