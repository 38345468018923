<ng-container *ngIf="!loadingError else error">


  <ng-container *ngIf="companyView$ | async; let companyView; else loading">
    <div class="flex-nowrap-center">
      <mat-card class="card-size card-margin">
        <div class="company-title overflow-ellipsis">{{ companyView.company.name }}
          <button [matMenuTriggerFor]="optionsMenu" class="more-button" mat-icon-button>
            <mat-icon matTooltip="{{'company.tooltip.more-options-for-structure' | translate}}">
              more_vert
            </mat-icon>
          </button>
        </div>

        <div class="company-subtitle"> {{ companyView.company.label }}</div>

        <mat-divider></mat-divider>

        <ng-container *ngIf="roleDepartmentIds$ | async; let roleDepartmentIds">
          <div *ngIf="roleDepartmentIds.currentlyManagerDepartmentIds.length > 0">
            <div class="tab-subtitle">{{'company.your-teams-as-manager' | translate}}</div>
            <div class="flex-wrap-spreadinner">
              <div *ngFor="let managerDepartmentId of roleDepartmentIds.currentlyManagerDepartmentIds">
                <app-okr-child-unit-preview-button [unitId]="managerDepartmentId"></app-okr-child-unit-preview-button>
              </div>
            </div>
          </div>

          <mat-divider></mat-divider>

          <div *ngIf="roleDepartmentIds.currentlyMemberDepartmentIds.length > 0">
            <div class="tab-subtitle">{{'company.your-teams-as-member' | translate}}</div>
            <div class="flex-wrap-spreadinner">
              <div *ngFor="let memberDepartmentId of roleDepartmentIds.currentlyMemberDepartmentIds">
                <app-okr-child-unit-preview-button [unitId]="memberDepartmentId"></app-okr-child-unit-preview-button>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="flex-nowrap-center">
          <div class="tab-limited-width">
            <!-- Tab title and add subdepartments buton-->
            <div class="flex-nowrap">
              <div class="f-3">
                <div class="tab-headline">{{'company.substructures' | translate}}</div>
              </div>
              <div class="icon-spacer f-1">
                <app-add-child-unit-button (clickedAddSubBranch)="clickedAddSubBranch(companyView.company)"
                                           (clickedAddSubDepartment)="clickedAddSubDepartment(companyView.company)"
                                           [cycleClosed]="companyView.cycle.isCycleClosed()"
                                           [topicSubject]="companyView.company"
                                           [userRole]="currentUserRole$ | async"></app-add-child-unit-button>
              </div>
            </div>
            <!-- Subdepartments -->
            <div *ngIf="companyView.company.okrChildUnitIds.length === 0"
                 class="tab-subtitle">{{'company.no-child-units' | translate}}</div>
            <div class="flex-wrap-spreadinner">
              <div *ngFor="let currentdepartmentId of companyView.company.okrChildUnitIds">
                <app-okr-child-unit-preview-button [unitId]="currentdepartmentId"></app-okr-child-unit-preview-button>
              </div>
            </div>
          </div>
        </div>

      </mat-card>
    </div>

    <!-- Options menu -->
    <mat-menu #optionsMenu="matMenu" xPosition="before">
      <ng-template matMenuContent>
        <button (click)="clickedDownloadExcelFileForCompany(companyView.company)" mat-menu-item>
          <mat-icon>save_alt</mat-icon>
          <span>{{'company.menu.export' | translate}}</span>
        </button>
        <button (click)="clickedDownloadExcelEmailFileForCompany(companyView.company)" mat-menu-item>
          <mat-icon>save_alt</mat-icon>
          <span>{{'company.menu.export-contacts' | translate}}</span>
        </button>
      </ng-template>
    </mat-menu>
  </ng-container>
</ng-container>

<ng-template #loading>
  <h3 class="card-margin">{{'company.loading-company' | translate}}</h3>
</ng-template>

<ng-template #error>
  <div class="card-margin">
    <h3>{{'company.error.error' | translate}}</h3>
    <p>{{'company.error.error-loading-company' | translate}}</p>
    <button color="primary" mat-button mat-raised-button
            routerLink="/companies">{{'company.error.navigate-to-structure-view' | translate}}</button>
  </div>

</ng-template>
