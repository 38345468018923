<app-okr-toolbar>
  <button (click)="navigateToCompanies()" class="navbar-item" mat-icon-button
          matTooltip="{{ 'user-management.tooltip.go-to-okr-unit-overview' | translate }}">
    <mat-icon>home</mat-icon>
  </button>
  <span>{{ 'user-management.user-management-toolbar-title' | translate }}</span>
</app-okr-toolbar>

<div class="container footer-margin">
  <div class="row">
    <mat-card class="col-m-12 col-l-12 col-xl-10 col-s-12 col-centered">
      <mat-card-title>
        <mat-icon>group</mat-icon>
        <span>{{ 'user-management.user-management-toolbar-title' | translate }}</span>
      </mat-card-title>

      <mat-card-content>
        <div class="row">
          <mat-checkbox
            (change)="onShowDeactivatedUserChange($event)">{{ 'user-management.checkbox' | translate }}</mat-checkbox>
          <mat-form-field class="form-whole-line col-11">
            <input (keyup)="applyFilter($event.target.value)" matInput placeholder="Filter">
          </mat-form-field>
          <div>
            <app-add-button (buttonClicked)="handleCsvImport()" [isDisabled]="isPlayground" matIcon="unarchive"
                            matTooltip="{{'user-management.tooltip.import-users-from-csv' | translate}}"
                            text="{{'user-management.import-users' | translate }}"></app-add-button>
            <app-add-button (buttonClicked)="handleCreate()" [isDisabled]="isPlayground" class="ml-10"
                            text="{{'user-management.add-new-user' | translate }}"></app-add-button>
          </div>
        </div>
        <table [dataSource]="rowData" mat-table matSort matSortActive="active" matSortDirection="desc">
          <ng-container matColumnDef="photo">
            <th *matHeaderCellDef mat-header-cell></th>
            <td *matCellDef="let user" mat-cell>
              <app-user-avatar [user]="user" size="38"></app-user-avatar>
            </td>
          </ng-container>
          <ng-container matColumnDef="active">
            <th *matHeaderCellDef mat-header-cell
                mat-sort-header>{{'user-management.active-table-header' | translate}}</th>
            <td *matCellDef="let user" mat-cell>
              <mat-icon *ngIf="user.active">check</mat-icon>
            </td>
          </ng-container>
          <ng-container matColumnDef="email">
            <th *matHeaderCellDef class="hidden-mobile-table" mat-header-cell
                mat-sort-header> {{'user-management.email' | translate }}</th>
            <td *matCellDef="let user" class="hidden-mobile-table" mat-cell>{{user.email}}</td>
          </ng-container>
          <ng-container matColumnDef="givenName">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> {{'user-management.name' | translate}}</th>
            <td *matCellDef="let user" class="name-column" mat-cell
                matTooltip="{{user.givenName + ' ' + user.surname}}">{{user.givenName + ' ' + user.surname}}</td>
          </ng-container>
          <ng-container class="cell" matColumnDef="department">
            <th *matHeaderCellDef class="hidden-mobile-table" mat-header-cell
                mat-sort-header> {{'user-management.department-table-header' | translate}}</th>
            <td *matCellDef="let user" class="hidden-mobile-table" mat-cell>{{user.department}}</td>
          </ng-container>
          <ng-container matColumnDef="jobTitle">
            <th *matHeaderCellDef class="hidden-mobile-table" mat-header-cell
                mat-sort-header> {{'user-management.job-title-table-header' | translate}}</th>
            <td *matCellDef="let user" class="hidden-mobile-table" mat-cell>{{user.jobTitle}}</td>
          </ng-container>
          <ng-container matColumnDef="isAdmin">
            <th *matHeaderCellDef class="hidden-mobile-table" mat-header-cell
                mat-sort-header> {{'user-management.admin-table-header' | translate}}</th>
            <td *matCellDef="let user" class="hidden-mobile-table" mat-cell>
              <mat-icon *ngIf="user.isAdmin">verified_user</mat-icon>
            </td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th *matHeaderCellDef mat-header-cell></th>
            <td *matCellDef="let user" mat-cell>
              <button (click)="handleEdit(user)" mat-icon-button
                      matTooltip="{{ 'user-management.tooltip.edit-user' | translate }}">
                <mat-icon>edit</mat-icon>
              </button>
              <button (click)="handleDeactivate(user)" *ngIf="currentUser$.getValue().id !== user.id && user.active"
                      [disabled]="this.isPlayground" [matTooltip]="this.deactivateUserTranslation" mat-icon-button>
                <mat-icon>delete</mat-icon>
              </button>
              <button (click)="handleActivate(user)" *ngIf="currentUser$.getValue().id !== user.id && !user.active"
                      mat-icon-button matTooltip="{{ 'user-management.tooltip.activate-user' | translate }}">
                <mat-icon>restore_from_trash</mat-icon>
              </button>
            </td>
          </ng-container>
          <tr *matHeaderRowDef="columnsToDisplay;" mat-header-row></tr>
          <tr *matRowDef="let myRowData; columns: columnsToDisplay" mat-row></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[10,20,25,50,100]" pageSize="25"></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>
