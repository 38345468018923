<app-okr-toolbar>
  <button (click)="navigateToCompanies()" class="navbar-item" mat-icon-button
          matTooltip="{{ 'no-mail-information.tooltip.go-to-okr-unit-overview' | translate }}">
    <mat-icon>home</mat-icon>
  </button>
</app-okr-toolbar><br>
<div class="container">
  <h1>{{ 'no-mail-information.title' | translate }}</h1>
  <p>{{ 'no-mail-information.info' | translate }}</p>
  <p>{{ 'no-mail-information.a-a-d' | translate }}</p>
  <ul>
    <li>{{ 'no-mail-information.feedback' | translate }}</li>
  </ul>
  <p>{{ 'no-mail-information.local' | translate }}</p>
  <ul>
    <li>{{ 'no-mail-information.feedback' | translate }}</li>
    <li>{{ 'no-mail-information.create-user-mails' | translate }}</li>
    <li>{{ 'no-mail-information.password-reset-mails' | translate }}</li>
  </ul>

  <button color="primary" mat-raised-button routerLink="/">{{ 'no-mail-information.back-to-home' | translate }}</button>


</div>
