<form [formGroup]="userSettingsForm" class="settingsForm">
  <mat-form-field class="form-whole-line">
    <mat-select (selectionChange)="onSelectCompany()" formControlName="defaultCompanyId"
                placeholder="{{ 'user-settings.default-company' | translate }}">
      <mat-option [value]="null"><span>{{ 'user-settings.no-company' | translate }}</span>
      </mat-option>
      <mat-option *ngFor="let company of (companies$ | async)" [value]="company.id">
        {{company.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="form-whole-line">
    <mat-select formControlName="defaultTeamId" placeholder="{{ 'user-settings.default-team' | translate }}">
      <mat-option [value]="null"><span>{{ 'user-settings.no-team' | translate }}</span></mat-option>
      <mat-option *ngFor="let department of (departments$ | async)" [value]="department.id">
        {{department.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
