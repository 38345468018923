<ng-container *ngIf="currentCycleList$ | async as currentCycleList">
  <ng-container *ngIf="currentCycle$ | async as currentCycle">
    <mat-form-field class="number-input history-cycle-input navbar-item-dropdown">
      <mat-select (selectionChange)="onSelectCycle($event.value)" [value]="currentCycle">

        <mat-select-trigger>
          {{currentCycle.abbreviation}} <br>
          <small>[{{currentCycle.startDate | dateFormat | async}} - {{currentCycle.endDate | dateFormat | async}}
            ]</small>
          <mat-icon *ngIf="currentCycle.cycleState === 'CLOSED'" class="padlock-margin">lock</mat-icon>
          <mat-icon *ngIf="currentCycle.cycleState === 'PREPARATION'" class="padlock-margin">watch_later
          </mat-icon>
        </mat-select-trigger>

        <mat-option *ngFor="let cycleListEntry of currentCycleList" [value]="cycleListEntry" class="option">
          {{cycleListEntry.abbreviation}} <br>
          <small>[{{cycleListEntry.startDate | dateFormat | async}} - {{cycleListEntry.endDate | dateFormat | async}}
            ]</small>
          <mat-icon *ngIf="cycleListEntry.cycleState === 'CLOSED'" class="padlock-margin">lock</mat-icon>
          <mat-icon *ngIf="cycleListEntry.cycleState === 'PREPARATION'" class="padlock-margin">watch_later
          </mat-icon>
        </mat-option>

      </mat-select>
    </mat-form-field>
  </ng-container>
</ng-container>
