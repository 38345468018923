<mat-expansion-panel (closed)="panelOpenState = false" (opened)="panelOpenState = true" [expanded]="panelOpenState"
                     class="taskboard-swimlane">
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{keyResult.keyResult}}
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="swimlane-content">
    <app-taskboard-column *ngFor="let tasksForState of tasksForStates" [id]="keyResult.id"
                          [isInteractive]="isInteractive" [keyResults]="keyResultList" [map]="tasksForState"
                          class="taskboard-column"></app-taskboard-column>
  </div>
</mat-expansion-panel>
