<div>
  {{ componentTypeTitle }}
  <div class="title-keyresult">
    {{ componentName }}
    <br>
    <br>
  </div>
  <div class="comments-title">{{ 'comment-view-dialog.title' | translate }}</div>
  <mat-dialog-content>

    <div *ngIf="this.commentList.length !== 0 else noComments">
      <div *ngIf="commentList else loading">
        <div *ngFor="let comment of commentList">
          <app-comment-card (deleteComment)="queryDeleteComment($event)" [comment]="comment"></app-comment-card>
          <mat-divider></mat-divider>
        </div>
      </div>
    </div>

    <!-- No comments in KeyResult -->
    <ng-template #noComments>
      <div class="no-comment-placeholder">{{ 'comment-view-dialog.no-comment' | translate }}</div>
      <mat-divider></mat-divider>
    </ng-template>
    <!-- Loading comment list placeholder -->
    <ng-template #loading>
      <mat-card *ngFor="let x of this.commentList">
        <br>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        <br>
      </mat-card>
    </ng-template>


    <div class="flex-nowrap">
      <mat-form-field class="full-width" hintLabel="{{getHintLabel$() | async}}">
        <textarea #input [(ngModel)]="newCommentText" [disabled]="isPostingComment" cdkTextareaAutosize matInput
                  maxlength="255" placeholder="{{ 'comment-view-dialog.write-comment' | translate }}"></textarea>
        <mat-hint align="end">{{input.value?.length || 0}}/255</mat-hint>
      </mat-form-field>
      <button (click)="postNewComment()" *ngIf="canPostNewComment(); else commentToSmall"
              [disabled]="!canPostNewComment()" class="m-24" mat-mini-fab
              matTooltip="{{ 'comment-view-dialog.tooltip.write-new-comment' | translate }}">
        <mat-icon class="v-top">send</mat-icon>
      </button>

      <ng-template #commentToSmall>
        <button (click)="postNewComment()" [disabled]="!canPostNewComment()" class="m-24" mat-mini-fab
                matTooltip="{{ 'comment-view-dialog.tooltip.comment-to-small' | translate }}">
          <mat-icon class="v-top">send</mat-icon>
        </button>
      </ng-template>
    </div>

  </mat-dialog-content>

  <mat-dialog-actions>
    <button (click)="clickedClose()" mat-stroked-button>{{ 'comment-view-dialog.close' | translate }}</button>
  </mat-dialog-actions>
</div>
