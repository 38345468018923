<ng-container>
  <button [matMenuTriggerFor]="actionDropdown" mat-icon-button>
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #actionDropdown="matMenu">
      <span [matTooltipDisabled]="canEditTopicDraft()" matTooltip="{{ getEditTooltipText$() | async}}">
        <button (click)="editTopicDraft()" [disabled]="!canEditTopicDraft()" mat-menu-item>
          <mat-icon>edit</mat-icon>
          <span>{{ 'topic-draft-action-button.edit' | translate }}</span>
        </button>
      </span>
    <span [matTooltipDisabled]="canDeleteTopicDraft()"
          matTooltip="{{ 'topic-draft-action-button.tooltip.only-admin-or-initiator-permission-to-delete' | translate }}">
      <button (click)="clickedDeleteTopicDraft()" [disabled]="!canDeleteTopicDraft()" mat-menu-item>
        <mat-icon>delete</mat-icon>
        <span>{{ 'topic-draft-action-button.delete-text' | translate }}</span>
      </button>
    </span>
    <span matTooltip="{{ 'topic-draft-action-button.tooltip.header' | translate }}">
      <button (click)="clickedOpenComments()" mat-menu-item>
        <mat-icon>chat</mat-icon>
        <span>{{ 'topic-draft-action-button.comments-text' | translate }}</span>
      </button>
    </span>
    <span [matTooltipDisabled]="isTopicDraftInSubmissionStage()" matTooltip="{{ getSubmissionTooltipText$() | async}}">
      <button (click)="submittingTopicDraft()" [disabled]="!isTopicDraftInSubmissionStage()" mat-menu-item>
        <mat-icon>exposure</mat-icon>
        <span>{{ getSubmissionButtonText$() | async }}</span>
      </button>
    </span>
    <span [matTooltipDisabled]="isTopicDraftConvertableToTeam()"
          matTooltip="{{getConvertToTeamTooltipText$() | async}}">
        <button (click)="clickedConvertToTeam()" [disabled]="!isTopicDraftConvertableToTeam()" mat-menu-item>
          <mat-icon>transform</mat-icon>
          <span>{{ 'topic-draft-action-button.create-team' | translate }}</span>
        </button>
      </span>
    <span [matTooltipDisabled]="isTopicDraftInApprovingStage()"
          matTooltip="{{ getApproveOrRejectTooltipText$() | async}}">
      <button (click)="approvingTopicDraft()" [disabled]="!isTopicDraftInApprovingStage()" mat-menu-item>
        <mat-icon>check_circle</mat-icon>
        <span>{{ getApprovalButtonText$() | async }}</span>
      </button>
    </span>
    <span [matTooltipDisabled]="isTopicDraftInApprovingStage()"
          matTooltip="{{ getApproveOrRejectTooltipText$() | async}}">
      <button (click)="rejectingTopicDraft()" [disabled]="!isTopicDraftInApprovingStage()" mat-menu-item>
        <mat-icon>cancel</mat-icon>
        <span>{{ getRejectionButtonText$() | async }}</span>
      </button>
    </span>
  </mat-menu>
</ng-container>
