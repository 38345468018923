<app-dialog-component (okEmitter)="saveObjective()" [formGroup]="taskForm" [isSaveButtonDisabled]="!isInteractive"
                      [title]="title">
  <form [formGroup]="taskForm">
    <mat-form-field class="form-whole-line">
      <input formControlName="title" matInput placeholder="{{ 'department-tab-task-form.title' | translate }}"
             type="text">
      <mat-error>
        <app-form-error [control]="taskForm.get('title')"></app-form-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form-whole-line">
      <mat-label>{{ 'department-tab-task-form.description' | translate }}</mat-label>
      <textarea formControlName="description" matInput></textarea>
      <!-- TODO: Enable resize again. TGohlisch, 19.09.2022-->
      <mat-error>
        <app-form-error [control]="taskForm.get('description')"></app-form-error>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="form-whole-line">
      <mat-select formControlName="assignedKeyResultId"
                  placeholder="{{ 'department-tab-task-form.select-parent-key-result' | translate }}">
        <mat-option [value]="null">
          <span>{{ 'department-tab-task-form.no-parent-key-result' | translate }}</span>
        </mat-option>
        <mat-optgroup *ngFor="let map of keyResultMaps$ |async" [label]="map.objective.name">
          <mat-option *ngFor="let keyResult of map.keyResults" [value]="keyResult.id">
            {{keyResult.keyResult}}
          </mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="users$ | async; let users" class="form-whole-line">
      <mat-select (selectionChange)="onSelectUser($event)" formControlName="assignedUserIds" multiple
                  placeholder="{{ 'department-tab-task-form.assigned-user-ids' | translate }}">
        <mat-option *ngFor="let user of users" [value]="user.id">
          {{user?.email | lowercase}} ({{user.surname}}, {{user.givenName}})
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="form-whole-line">
      <mat-select formControlName="taskStateId" placeholder="{{ 'department-tab-task-form.task-state' | translate }}">
        <mat-option *ngFor="let state of states" [value]="state.id">
          {{state.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
  <ng-container *ngIf="taskRevisions$ | async; let taskRevisions; else loading">
    <table class="mat-table" *ngIf="taskRevisions.length > 0">
      <thead>
      <tr class="mat-header-row">
        <th class="mat-header-cell">&nbsp;</th>
        <th class="mat-header-cell">{{ 'department-tab-task-form.revisions.date' | translate }}
          <mat-icon style="transform: scale(0.5); vertical-align: -8px;">arrow_downward</mat-icon>
        </th>
        <th class="mat-header-cell">{{ 'department-tab-task-form.revisions.user' | translate }}</th>
        <th class="mat-header-cell">{{ 'department-tab-task-form.revisions.field' | translate }}</th>
        <th class="mat-header-cell">{{ 'department-tab-task-form.revisions.old-value' | translate }}</th>
        <th class="mat-header-cell">{{ 'department-tab-task-form.revisions.new-value' | translate }}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let revision of taskRevisions" class="mat-row">
        <td class="mat-cell type">
          <mat-icon
            style="transform: scale(0.7);">{{revision.revisionType === "ADD" ? "fiber_new" : (revision.revisionType === "MOD" ? "edit" : "")}}</mat-icon>
        </td>
        <td class="mat-cell date">{{revision.date | date:'mediumDate': undefined: (currentLanguage || 'de-DE')}}</td>
        <td class="mat-cell user">
          <app-taskboard-users *ngIf="revision.user" [users]="[revision.user]"></app-taskboard-users>
          <ng-container *ngIf="revision.revisionType === 'ADD' && !revision.user">
            <mat-icon>device_unknown</mat-icon>
          </ng-container>
        </td>
        <td
          class="mat-cell field">{{ 'department-tab-task-form.revisions.fields.' + revision.changedField | translate }}</td>
        <ng-container [ngSwitch]="revision.revisionValueType">
          <ng-container *ngSwitchCase="'STRING'">
            <td class="mat-cell changed-field">{{revision.oldValue === '' ? '-' : revision.oldValue}}
            </td>
            <td class="mat-cell changed-field">{{revision.newValue === '' ? '-' : revision.newValue}}
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'USER_COLLECTION'">
            <td class="mat-cell changed-field">
              <ng-container *ngIf="revision.oldValue?.length === 0">-</ng-container>
              <ng-container *ngIf="revision.oldValue.length > 0 ">
                <app-taskboard-users [users]="revision.oldValue"></app-taskboard-users>
              </ng-container>
            </td>
            <td class="mat-cell changed-field">
              <ng-container *ngIf="revision.newValue.length === 0">-</ng-container>
              <ng-container *ngIf="revision.newValue.length > 0 ">
                <app-taskboard-users [users]="revision.newValue"></app-taskboard-users>
              </ng-container>
            </td>
          </ng-container>
        </ng-container>
      </tr>
      </tbody>
    </table>
  </ng-container>
  <ng-template #loading>
    <app-loading-spinner></app-loading-spinner>
  </ng-template>

</app-dialog-component>
