<div [class.fixed]="fixedPosition">
  <mat-toolbar [color]="'primary'" class="app-toolbar">
    <mat-toolbar-row>
      <button mat-icon-button routerLink="/">
        <mat-icon>
          home
        </mat-icon>
      </button>
      <button (click)="pickLanguageClicked()" mat-icon-button>
        <mat-icon>
          language
        </mat-icon>
      </button>
      <ng-content></ng-content>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="toolbar-spacer"></div>
</div>
