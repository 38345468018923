<app-okr-toolbar>
  <button [routerLink]="['/companies']" class="navbar-item" mat-icon-button
          matTooltip="{{'topic-drafts.home-button.tooltip' | translate}}">
    <mat-icon>home</mat-icon>
  </button>
  <span class="full-text" id="topic-drafts-page-topic">{{'topic-drafts.navbar.title' | translate}}</span>
  <span class="short-text">{{'topic-drafts.navbar.title-short' | translate}}</span>
  <span class="fill-space"></span>
</app-okr-toolbar>

<div class="content-wrapper">
  <div class="content">
    <app-topic-draft-list [topicDrafts]="rowData.data"></app-topic-draft-list>
    <app-add-button (buttonClicked)="clickedAddTopicDraft()" class="right"
                    text="{{'topic-drafts.add-topic-draft-button' | translate}}"></app-add-button>
  </div>
</div>


