import { Component } from '@angular/core';

@Component({
  selector: 'app-demo-credits',
  templateUrl: './demo-credits.component.html',
  styleUrls: ['./demo-credits.component.scss'],
})
export class DemoCreditsComponent {

}
