<app-dialog-component (okEmitter)="onSave()"
                      [isSaveButtonDisabled]="rowData.data.length === 0 || warnings.duplicateEmailAdresses">
  <p>{{ 'import-csv-dialog.description' | translate }}</p>
  <p *ngIf="rowData.data.length > 0">{{ 'import-csv-dialog.amount-of-entries-loaded' | translate }}</p>
  <p *ngIf="warnings">
    <mat-error *ngIf="warnings.tooManyFields">{{ 'import-csv-dialog.to-many-entries-warning' | translate }}</mat-error>
    <mat-error
      *ngIf="warnings.duplicateEmailAdresses">{{ 'import-csv-dialog.duplicate-email-adresses-warning' | translate }}</mat-error>
  </p>
  <mat-form-field [formGroup]="fileForm">
    <ngx-mat-file-input [formControl]="fileForm.controls.csvFile" accept=".csv, .txt"
                        placeholder="{{ 'import-csv-dialog.csv-file' | translate }}"></ngx-mat-file-input>
    <mat-icon matSuffix>folder</mat-icon>
  </mat-form-field>
  <div style="max-height: 600px; overflow-y: auto">
    <table [dataSource]="rowData" mat-table matSort>
      <ng-container matColumnDef="givenName">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'import-csv-dialog.name' | translate }}</th>
        <td *matCellDef="let user" mat-cell>{{user.givenName + ' ' + user.surname}}</td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'import-csv-dialog.email' | translate }}</th>
        <td *matCellDef="let user" mat-cell>{{user.email}}</td>
      </ng-container>
      <ng-container matColumnDef="department">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'import-csv-dialog.department' | translate }}</th>
        <td *matCellDef="let user" mat-cell>{{user.department}}</td>
      </ng-container>
      <ng-container matColumnDef="jobTitle">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'import-csv-dialog.job-title' | translate }}</th>
        <td *matCellDef="let user" mat-cell>{{user.jobTitle}}</td>
      </ng-container>

      <tr *matHeaderRowDef="columnsToDisplay;" mat-header-row sticky></tr>
      <tr *matRowDef="let myRowData; columns: columnsToDisplay" mat-row></tr>
    </table>
  </div>
  <mat-paginator [pageSizeOptions]="[10,20,25,50,100]" pageSize="25"></mat-paginator>
</app-dialog-component>
