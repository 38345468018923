<div class="user-dialog-component-wrapper">
  <app-dialog-component (okEmitter)="onSave()" *ngIf="userForm" [formGroup]="userForm" [title]="formData.title">
    <app-user-form *ngIf="userForm" [canEditAdminStatus]="editedUserIsCurrentUser$ | async"
                   [userForm]="userForm"></app-user-form>
    <br>
    <button (click)="resetUserPassword()" *ngIf="formData.user" [disabled]="resetPasswordButtonDisabled"
            class="small-padding mb-10 mt-10" color="primary"
            mat-button>{{ 'user-dialog.reset-password' | translate }}</button>
  </app-dialog-component>
</div>
