<h1 class="form-header" mat-dialog-title>
  <span>{{ 'settings-form.settings' | translate }}</span>
</h1>

<mat-dialog-content>

  <mat-tab-group dynamicHeight>
    <mat-tab label="{{ 'settings-form.settings' | translate }}">
      <app-user-settings (valid)="userSettingsValid = $event"></app-user-settings>
    </mat-tab>
    <mat-tab [disabled]="(isCurrentUserAdmin$ | async) === false"
             label="{{ 'settings-form.admin-settings' | translate }}">
      <app-admin-settings (valid)="adminSettingsValid = $event"></app-admin-settings>
    </mat-tab>
  </mat-tab-group>

</mat-dialog-content>

<mat-dialog-actions>
  <button (click)="onSave()"
          [disabled]="!userSettingsValid || (adminSettingsValid && isCurrentUserAdmin$ | async) === false"
          color="primary" mat-button>
    <span>{{ 'settings-form.save' | translate }}</span></button>
  <button (click)="closeDialog()" color="warn" mat-button>
    <span>{{ 'settings-form.cancel-button-text' | translate }}</span></button>
</mat-dialog-actions>
