<mat-sidenav-container autosize class="example-container footer-margin">

  <mat-sidenav #sideNav [fixedInViewport]="mobileQuery.matches" [mode]="mobileQuery.matches ? 'over' : 'side'"
               class="list-content" opened>

    <div *ngIf="mobileQuery.matches" [style.marginTop.px]="37">
      <app-cycle-list-dropdown></app-cycle-list-dropdown>
    </div>

    <div (click)="closeSideNavMobileOnly()" *ngIf="(currentCompany$ | async ) as company">
      <div class="flex-nowrap">
        <button [routerLink]="['./companies/'+company.id]" class="entry-title" mat-button>
          <mat-icon class="icon-spacer">business</mat-icon>
          {{company.name}}
        </button>
      </div>
    </div>

    <div *ngFor="let unitSchema of (currentUnitSchema$ | async)">
      <app-navigation-list-entry (click)="closeSideNavMobileOnly()" [schema]="unitSchema"></app-navigation-list-entry>
    </div>

  </mat-sidenav>

  <mat-sidenav-content>
    <ng-content></ng-content>
  </mat-sidenav-content>

</mat-sidenav-container>
