<form (capsLock)="capsLock=$event" (keydown.enter)="login()" [formGroup]="loginForm" appCapsLock>
  <app-non-logged-in-card toolbarTitle="Login">
    <mat-card-header>
      <mat-card-title>Burning OKR - Login</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <table class="fullWidth">
        <tbody>
        <tr>
          <td>
            <mat-form-field class="form-whole-line">
              <input autocomplete="username" formControlName="email" matInput
                     placeholder="{{ 'login.email' | translate }}" required type="email">
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            <mat-form-field class="form-whole-line">
              <input autocomplete="password" formControlName="password" matInput
                     placeholder="{{ 'login.password' | translate }}" required type="password">
            </mat-form-field>
          </td>
        </tr>
        </tbody>
      </table>
      <mat-error *ngIf="errorType === authErrors.UNAUTHORIZED">{{ 'login.error-unauthorized' | translate }}</mat-error>
      <mat-error
        *ngIf="errorType === authErrors.INVALID_GRANT">{{ 'login.error-invalid-grant' | translate }}</mat-error>
      <mat-error *ngIf="capsLock" class="error-span">{{ 'login.caps-lock-on' | translate }}</mat-error>
    </mat-card-content>
    <mat-card-actions class="row">
      <button (click)="login()" [disabled]="loginForm.invalid" class="col-4" color="primary" mat-raised-button
              mat-ripple type="submit">
        Login
      </button>
      <a mat-button routerLink="/auth/resetpassword" routerLinkActive="active">{{ 'login.button' | translate }}</a>
    </mat-card-actions>
  </app-non-logged-in-card>
</form>
