import { Component } from '@angular/core';

@Component({
  selector: 'app-demo-privacy-policy',
  templateUrl: './demo-privacy-policy.component.html',
  styleUrls: ['./demo-privacy-policy.component.scss'],
})
export class DemoPrivacyPolicyComponent {

}
