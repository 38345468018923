<app-dialog-component (okEmitter)="saveCompany()" [formGroup]="companyForm" [title]="title">
  <ng-container>
    <form [formGroup]="companyForm">
      <mat-form-field class="name">
        <input formControlName="name" matInput placeholder="{{'okr-unit-form.placeholder.name' | translate}}"
               type="text">
        <mat-error>
          <app-form-error [control]="companyForm.get('name')"></app-form-error>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="name">
        <input formControlName="label" matInput placeholder="{{'okr-unit-form.placeholder.label' | translate}}"
               type="text">
        <mat-error>
          <app-form-error [control]="companyForm.get('label')"></app-form-error>
        </mat-error>
      </mat-form-field>
    </form>
  </ng-container>
</app-dialog-component>
