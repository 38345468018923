<mat-toolbar [color]="'primary'" class="app-toolbar">
  <mat-toolbar-row>
    <button *ngIf="isPlayground" class="button-bag-layout hidden-s-down" id="mainPageButton" mat-stroked-button
            routerLink="/">{{'okr-toolbar.main-page' | translate}}</button>
    <button class="hidden-s-up" mat-icon-button matTooltip="{{'okr-toolbar.return-to-demo-text' | translate}}"
            routerLink="/">
      <mat-icon>cancel</mat-icon>
    </button>
    <ng-content></ng-content>
    <span class="fill-space"></span>

    <app-reset-countdown *ngIf="isPlayground" class="hidden-s-down center-countdown"></app-reset-countdown>

    <div *ngIf="isPlayground === false && (hasMailConfigured$ | async) === false" class="warningText hidden-m-down">
      <span class="break-word">{{'okr-toolbar.warning.no-smtp-text' | translate}}</span>
      <br>
      <a [routerLink]="['/noMailInformation']"
         class="warningText break-word">{{'okr-toolbar.warning.no-smtp-link' | translate}}</a>
    </div>

    <span class="fill-space"></span>
    <div class="user-interactions">
      <div class="adminIcon">
        <mat-icon *ngIf="isCurrentUserAdmin$ | async" class="icon-spacer"
                  matTooltip="{{'okr-toolbar.admin-icon.tooltip.is-admin' | translate}}">
          verified_user
        </mat-icon>
      </div>
      <span *ngIf="currentUser$ | async as currentUser"
            [matTooltip]="currentUser.givenName + ' ' + currentUser.surname">
         <app-user-avatar [user]="currentUser" class="icon-spacer-large profileIcon" size="40"></app-user-avatar>
      </span>

      <button [matMenuTriggerFor]="menu" class="icon-spacer-large menuIcon" mat-icon-button
              matTooltip="{{'okr-toolbar.tooltip.menue' | translate}}">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<div class="toolbar-spacer"></div>

<!-- Interactions menu -->
<mat-menu #menu="matMenu">
  <button class="hidden-s-up" disabled mat-menu-item>
    <mat-icon>timer</mat-icon>
    <app-reset-countdown></app-reset-countdown>
  </button>


  <!-- show menu settings-->
  <ng-container *ngIf="(isLocalUserbase$ | async) === false && isPlayground === false">
    <button *ngIf="isCurrentUserAdmin$ | async" mat-menu-item routerLink="/admin">
      <mat-icon>edit_attributes</mat-icon>
      <span>{{'okr-toolbar.menu.admin-panel-text' | translate}}</span>
    </button>
  </ng-container>
  <button mat-menu-item routerLink="/submitted-topic-drafts">
    <mat-icon>assignment</mat-icon>
    <span>{{'okr-toolbar.menu.topic-drafts-text' | translate}}</span>
  </button>
  <button *ngIf="getCurrentCompanyId$() | async; let currentCompanyId" [disabled]="isPlayground" mat-menu-item
          routerLink="/companies/{{currentCompanyId}}/dashboard-overview">
    <mat-icon>dashboard</mat-icon>
    <span>{{'okr-toolbar.menu.dashboard-overview' | translate}}</span>
  </button>
  <button (click)="routeToCycleAdminPanel()" *ngIf="isCycleManagementOptionVisible && (isCurrentUserAdmin$ | async)"
          mat-menu-item>
    <mat-icon>history</mat-icon>
    <span>{{'okr-toolbar.menu.cycle-administration-text' | translate}}</span>
  </button>
  <button (click)="openSettings()" [disabled]="isPlayground" mat-menu-item>
    <mat-icon>settings_applications</mat-icon>
    <span>{{'okr-toolbar.menu.settings-text' | translate}}</span>
  </button>
  <button *ngIf="(isLocalUserbase$ | async) && (isCurrentUserAdmin$ | async)" mat-menu-item routerLink="/auth/users">
    <mat-icon>account_box</mat-icon>
    <span>{{'okr-toolbar.menu.user-managment-text' | translate}}</span>
  </button>
  <button (click)="openPasswordChangeForm()" *ngIf="(isLocalUserbase$ | async)" [disabled]="isPlayground" mat-menu-item>
    <mat-icon>vpn_key</mat-icon>
    <span>{{'okr-toolbar.menu.change-password-text'| translate}}</span>
  </button>
  <button [disabled]="isPlayground" [routerLink]="['/auth', 'logout']" mat-menu-item>
    <mat-icon>power_settings_new</mat-icon>
    <span>{{'okr-toolbar.menu.logout-text' | translate}}</span>
  </button>
  <button (click)="pickLanguageClicked()" mat-menu-item>
    <mat-icon>language</mat-icon>
    <span>{{'okr-toolbar.menu.language-picker' | translate}}</span>
  </button>
  <button (click)="openVersionChangelog()" mat-menu-item>
    <mat-icon>info</mat-icon>
    <span>v{{ versionString }}</span>
  </button>
</mat-menu>
