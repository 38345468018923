<h1 class="form-header" mat-dialog-title>{{ 'version-form.change-log' | translate }}</h1>

<mat-dialog-content>
  <div *ngFor="let change of versionChanges">
    <h4>{{change.version}}</h4>
    <ul *ngFor="let changePoint of change.changes">
      <li>{{changePoint}}</li>
    </ul>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button (click)="closeDialog()" color="warn" mat-button>{{ 'version-form.close' | translate }}</button>
</mat-dialog-actions>
