<mat-card class="cycle-card">
  <mat-card-title>{{cycle.name}}</mat-card-title>
  <mat-card-content>
    {{cycle.startDate | dateFormat: true | async}} - {{cycle.endDate | dateFormat: true | async}}
  </mat-card-content>
  <mat-card-content>
    <i *ngIf="cycle.isVisible; else invisible">
      <span>{{ 'cycle-admin-card.visible' | translate }}</span>
    </i>
    <ng-template #invisible>
      <i>
        <span>{{ 'cycle-admin-card.hidden' | translate }}</span>
      </i>
    </ng-template>
  </mat-card-content>
  <mat-card-actions class="item-actions">
    <button (click)="$event.stopPropagation(); editCycle()" mat-stroked-button>
      <mat-icon>edit</mat-icon>
      <span>{{ 'cycle-admin-card.edit-cycle' | translate }}</span>
    </button>

    <button (click)="$event.stopPropagation(); deleteCycle()" [disabled]="cycle.cycleState === cycleState.ACTIVE"
            mat-stroked-button>
      <mat-icon>delete</mat-icon>
      <span>{{ 'cycle-admin-card.delete-cycle' | translate }}</span>
    </button>

  </mat-card-actions>
</mat-card>
