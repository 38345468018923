<app-non-logged-in-card toolbarTitle="{{ 'reset-password.password-reset-title' | translate }}">
  <mat-card-header>
    <mat-card-title>{{ 'reset-password.password-reset-title' | translate }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="passwordResetForm">
      <table class="fullWidth">
        <tr>
          <td>
            <mat-form-field class="form-whole-line">
              <input autocomplete="username" formControlName="email" matInput
                     placeholder="{{ 'reset-password.email' | translate }}" required type="email">
              <mat-error
                *ngIf="passwordResetForm.invalid">{{ 'reset-password.error-invalid-email' | translate }}</mat-error>
            </mat-form-field>
          </td>
        </tr>
      </table>
    </form>
  </mat-card-content>
  <mat-card-actions class="flex-nowrap">
    <button (click)="sendResetPasswordMail()" [disabled]="passwordResetForm.invalid" color="primary" mat-raised-button
            mat-ripple>{{ 'reset-password.reset-password' | translate }}</button>
    <div *ngIf="emailHasBeenSentOut" class="message mat-accent">
      <mat-icon>mail_outline</mat-icon>
      <p>{{ 'reset-password.password-reset-email-has-been-send-out' | translate }}</p>
    </div>

  </mat-card-actions>
</app-non-logged-in-card>
