<mat-dialog-content class="padding-dialog-content">
  <form [formGroup]="submittedTopicDraftDetailsForm">
    <div class="topic-draft-details">

      <div class="information-row"><h1>{{'topic-draft-details-dialogue.title' | translate}}</h1></div>

      <div class="information-row">
        <div class="information-row-item status-display">
          <app-status-dot [state]="topicDraft.currentStatus" class="display-status-dot mr-30"></app-status-dot>
          <mat-form-field>
            <mat-label>Status</mat-label>
            <mat-select disabled formControlName="currentStatus">
              <mat-option [value]="enumStatus.draft">
                <div>{{'topic-draft-details-dialogue.status.template' | translate}}</div>
              </mat-option>
              <mat-option [value]="enumStatus.submitted">
                <div>{{'topic-draft-details-dialogue.status.submitted' | translate}}</div>
              </mat-option>
              <mat-option [value]="enumStatus.approved">
                <div>{{'topic-draft-details-dialogue.status.approved' | translate}}</div>
              </mat-option>
              <mat-option [value]="enumStatus.rejected">
                <div>{{'topic-draft-details-dialogue.status.rejected' | translate}}</div>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="information-row">
          <div class="information-row-item fullWidth">
            <mat-form-field class="fullWidth">
              <mat-label>{{'topic-draft-details-dialogue.field.topic' | translate}}:</mat-label>
              <input formControlName="name" matInput readonly>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="information-row">
        <div class="information-row-item">
          <mat-form-field>
            <mat-label>{{'topic-draft-details-dialogue.field.begin' | translate}}</mat-label>
            <input formControlName="beginning" matInput readonly>
          </mat-form-field>
        </div>
      </div>

      <div
        class="topic-details-subheading information-row">{{'topic-draft-details-dialogue.field.initiator' | translate}}:
      </div>
      <app-user-minibutton [canBeRemoved]="false" [userId]="topicDraft.initiator.id"
                           class="no-margin-user-button"></app-user-minibutton>

      <div class="fullWidth mt-30">
        <mat-form-field class="fullWidth">
          <span class="topic-details-subheading">{{'topic-draft-details-dialogue.field.contribution' | translate}}
            :</span>
          <div class="multiline-text-wrapper mt-10">
            <textarea class="multiline-text" formControlName="contributesTo" matInput readonly rows="2"></textarea>
          </div>
        </mat-form-field>
      </div>
      <div class="fullWidth">
        <mat-form-field class="fullWidth">
          <span class="topic-details-subheading">{{'topic-draft-details-dialogue.field.handover' | translate}}:</span>
          <div class="multiline-text-wrapper mt-10">
            <textarea class="multiline-text" formControlName="handoverPlan" matInput readonly rows="2"></textarea>
          </div>
        </mat-form-field>
      </div>
      <div class="fullWidth">
        <mat-form-field class="fullWidth">
          <span class="topic-details-subheading">{{'topic-draft-details-dialogue.field.dependencies' | translate}}
            :</span>
          <div class="multiline-text-wrapper mt-10">
            <textarea class="multiline-text" formControlName="dependencies" matInput readonly rows="2"></textarea>
          </div>
        </mat-form-field>
      </div>
      <div class="fullWidth">
        <mat-form-field class="fullWidth">
          <span class="topic-details-subheading">{{'topic-draft-details-dialogue.field.delimitation' | translate}}
            :</span>
          <div class="multiline-text-wrapper mt-10">
            <textarea class="multiline-text" formControlName="delimitation" matInput readonly rows="2"></textarea>
          </div>
        </mat-form-field>
      </div>
      <div class="fullWidth">
        <mat-form-field class="fullWidth">
          <span class="topic-details-subheading">{{'topic-draft-details-dialogue.field.resources' | translate}}:</span>
          <div class="multiline-text-wrapper mt-10">
            <textarea class="multiline-text" formControlName="resources" matInput readonly rows="2"></textarea>
          </div>
        </mat-form-field>
      </div>
      <div class="fullWidth">
        <mat-form-field class="fullWidth">
          <span class="topic-details-subheading">{{'topic-draft-details-dialogue.field.description' | translate}}
            :</span>
          <div class="multiline-text-wrapper mt-10">
            <textarea class="multiline-text" formControlName="description" matInput readonly rows="2"></textarea>
          </div>
        </mat-form-field>
      </div>

      <mat-expansion-panel class="mb-20 mt-10 padding-user-list">
        <mat-expansion-panel-header class="pt-10 pb-10">
          <mat-panel-title>
            <div class="topic-details-subheading">{{'topic-draft-details-dialogue.field.start-team' | translate}}</div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngFor="let user of topicDraft.startTeam">
          <app-user-minibutton [canBeRemoved]="false" [userId]="user"
                               class="margin-user-list responsive-hidden-display"></app-user-minibutton>
        </div>
        <span *ngIf="topicDraft.startTeam.length === 0"
              class="zero-team-member-information">{{'topic-draft-details-dialogue.field.no-team-members' | translate}}</span>
      </mat-expansion-panel>

      <mat-expansion-panel class="mb-10 mt-10 padding-user-list">
        <mat-expansion-panel-header class="pt-10 pb-10">
          <mat-panel-title>
            <div
              class="topic-details-subheading">{{'topic-draft-details-dialogue.field.stakeholder-team' | translate}}</div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngFor="let user of topicDraft.stakeholders">
          <app-user-minibutton [canBeRemoved]="false" [userId]="user"
                               class="margin-user-list responsive-hidden-display"></app-user-minibutton>
        </div>
        <span *ngIf="topicDraft.stakeholders.length === 0"
              class="zero-team-member-information">{{'topic-draft-details-dialogue.field.no-team-members' | translate}}</span>
      </mat-expansion-panel>

    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <span
    [matTooltipDisabled]="canEdit && (topicDraft.currentStatus === enumStatus.submitted || topicDraft.currentStatus === enumStatus.draft)"
    matTooltip="{{'topic-draft-details-dialogue.button.tooltip-no-permission' | translate}}">
    <button (click)="editDialog()"
            [disabled]="!canEdit || topicDraft.currentStatus === enumStatus.approved || topicDraft.currentStatus === enumStatus.rejected"
            color="primary" mat-button>
     <span>{{'topic-draft-details-dialogue.button.edit' | translate}}</span>
    </button>
  </span>
  <button (click)="closeDialog()" color="warn"
          mat-button>{{'topic-draft-details-dialogue.button.close' | translate}}</button>
</mat-dialog-actions>
