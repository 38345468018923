<div class="d-flex center middle">
  <div class="f-3 text-center">
    <div class="tab-headline">Objectives</div>
  </div>
</div>

<div *ngIf="objectiveList; else loading">
  <div *ngIf="objectiveList.length === 0"
       class="centered-hint">{{ 'okr-child-unit-overview-tab.no-objectives' | translate }}</div>
  <div (cdkDropListDropped)="dropObjective($event)"
       [cdkDropListDisabled]="!(currentUserRole.isAtleastOKRMember() && !cycle.isCycleClosed())" cdkDropList>
    <div *ngFor="let objective of objectiveList; let i = index" cdkDrag>
      <app-objective (moveObjectiveToBottom)="moveObjectiveToBottom($event)"
                     (moveObjectiveToTop)="moveObjectiveToTop($event)" [currentUserRole]="currentUserRole"
                     [cycle]="cycle" [listNumber]="i+1" [objectiveList]="objectiveList" [objective]="objective">
        <div *ngIf="currentUserRole.isAtleastOKRMember() && !cycle.isCycleClosed()" class="icon-spacer">
          <mat-icon cdkDragHandle>drag_indicator</mat-icon>
        </div>
      </app-objective>
    </div>
  </div>
</div>

<div *ngIf="currentUserRole.isAtleastOKRMember() && !cycle.isCycleClosed()" class="add-objective-button-container">
  <app-add-button (buttonClicked)="clickedAddObjective()"
                  text="{{'okr-child-unit-overview-tab.create-new-objective' | translate}}"></app-add-button>
</div>

<!-- Placeholder for loading -->
<ng-template #loading>
  <mat-card *ngFor="let placeholder of okrChildUnit.objectives" class="placeholder-objective">
    <span>{{ 'okr-child-unit-overview-tab.load-objective' | translate }}</span>
    <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
    <br>
  </mat-card>
</ng-template>
