<div class="app-container footer-margin">
  <app-okr-toolbar-bare>
    <h1>{{toolbarTitle}}</h1>
  </app-okr-toolbar-bare>

  <div class="container">
    <div class="row">
      <mat-card class="col-l-5 col-s-12 col-centered">
        <ng-content></ng-content>
      </mat-card>
    </div>
  </div>
</div>


