<app-okr-toolbar *ngIf="userIsLoggedIn; else notLoggedIn">
  <button (click)="navigateToCompanies()" class="navbar-item" mat-icon-button
          matTooltip="{{ 'error.tooltip.go-to-okr-unit-overview' | translate }}">
    <mat-icon>home</mat-icon>
  </button>
  <button (click)="navigateToPrevious()" class="navbar-item" mat-icon-button
          matTooltip="{{ 'error.tooltip.navigate-to-previous' | translate }}">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <span>{{ 'error.error-list' | translate }}</span>
</app-okr-toolbar>
<ng-template #notLoggedIn>
  <app-okr-toolbar-bare>
    <button (click)="navigateToPrevious()" class="navbar-item" mat-icon-button
            matTooltip="{{ 'error.tooltip.navigate-to-previous' | translate }}">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <span>{{ 'error.error-list' | translate }}</span>
  </app-okr-toolbar-bare>
</ng-template>
<div class="container">
  <div class="row">
    <div *ngFor="let err of errors$ | async" class="col-l-6">
      <mat-card>
        <mat-card-header>
          <div mat-card-avatar>{{err.status}}</div>
          <mat-card-title>{{err.error ? (err.error.errorInformation ? err.error.errorInformation : (err.error.message) ?
            err.error.message : err.error) : err.message}}
          </mat-card-title>
          <mat-card-subtitle *ngIf="err.error && err.error.errorId">
            <span><span>{{ 'error.error-id' | translate }}</span> {{err.error?.errorId}}</span>
          </mat-card-subtitle>
        </mat-card-header>
      </mat-card>
    </div>
  </div>


</div>
