<form class="input-form">
  <mat-form-field class="input-full-width">
    <input #inputField (focus)="focusChanged()" [formControl]="inputFormControl" [matAutocomplete]="auto"
           attr.aria-label="{{'user-autocomplete-input.label' | translate}}" matInput
           placeholder="{{ placeHolderText }}" type="text">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedUser($event)" [displayWith]="getDisplayName"
                      autoActiveFirstOption>
      <mat-option *ngFor="let user of filteredUsers$ | async" [value]="user">
        <mat-icon>person</mat-icon>
        <span>{{ user.surname }}, {{ user.givenName }}</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
