<app-non-logged-in-card toolbarTitle="{{'set-password.toolbar-title' | translate}}">
  <mat-card-header>
    <mat-card-title>{{ 'set-password.set-new-password-instruction' | translate }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="newPasswordForm" class="newPasswordForm">
      <mat-form-field class="form-whole-line">
        <input formControlName="newPassword" matInput placeholder="{{ 'set-password.new-password' | translate }}"
               required type="password">
      </mat-form-field>

      <mat-form-field class="form-whole-line">
        <input formControlName="newPasswordRepetition" matInput
               placeholder="{{ 'set-password.new-password-repetition' | translate }}" required type="password">
      </mat-form-field>
    </form>
  </mat-card-content>
  <mat-card-actions class="row">
    <button (click)="setPassword()" [disabled]="newPasswordForm.invalid || this.submitted" color="primary" mat-button
            mat-raised-button mat-ripple>{{ 'set-password.reset-password' | translate }}</button>
    <mat-error *ngIf="newPasswordForm.get('newPassword').valid
                 && newPasswordForm.invalid"
               class="error-span">{{ 'set-password.passwords-do-not-match' | translate }}</mat-error>
    <mat-error *ngIf="newPasswordForm.get('newPassword').invalid"
               class="error-span">{{ 'set-password.new-password-invalid' | translate }}</mat-error>
  </mat-card-actions>
</app-non-logged-in-card>
