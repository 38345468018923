<form [formGroup]="adminSettingsForm" class="settingsForm">
  <ng-container formArrayName="settings">
    <ng-container *ngFor="let setting of settings.controls">
      <mat-form-field
        *ngIf="setting.controls.type.value === 'text' || setting.controls.type.value === 'number'; else checkbox"
        class="fullWidth">
        <!-- Intellisense will not work on the controls field -->
        <mat-label>{{configurationNames[setting.controls.name.value]}}</mat-label>
        <input [disabled]="setting.controls.name.value === 'general_frontend-base-url' && (isAzure$ | async)"
               [formControl]="setting.controls.value" [placeholder]="configurationNames[setting.controls.name.value]"
               [type]="setting.controls.type.value" matInput>
      </mat-form-field>
      <ng-template #checkbox>
        <mat-checkbox [checked]="setting.controls.value === 'true'"
                      [formControl]="setting.controls.value"></mat-checkbox>
        <mat-label> {{ configurationNames[setting.controls.name.value]}}</mat-label>
      </ng-template>
    </ng-container>
  </ng-container>
</form>

