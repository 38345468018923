<app-okr-toolbar>
  <button (click)="routeToCompany()" class="toolbar-margin-icon" mat-icon-button
          matTooltip="{{ 'cycle-admin-container.tooltip.go-back-to-company' | translate }}">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <button (click)="navigateToCompanies()" class="navbar-item" mat-icon-button
          matTooltip="{{ 'cycle-admin-container.tooltip.go-to-okr-unit-overview' | translate }}">
    <mat-icon>home</mat-icon>
  </button>
  <span>{{ 'cycle-admin-container.administration' | translate }}</span>
  <span class="fill-space"></span>
</app-okr-toolbar>
<div class="margin-toolbar item-actions">
  <h1> {{ company.name }}</h1>
  <app-add-button (buttonClicked)="createCycle()"
                  text="{{ 'cycle-admin-container.create-new-cycle' | translate }}"></app-add-button>
</div>
<div *ngFor="let cycle of (cycles$ | async)">
  <app-cycle-admin-card (cycleChanged)="loadCycles()" [allCyclesOfCompany$]="cycles$"
                        [cycle]="cycle"></app-cycle-admin-card>
</div>
