<div class="flex-nowrap-center">
  <div class="tab-limited-width">
    <div class="text-center">
      <div class="tab-headline">{{ 'department-tab-team.members-of-unit' | translate }}</div>
    </div>
    <div class="flex-wrap-spreadinner-nocenter">
      <div *ngIf="department.okrMasterId === null">
        <div class="tab-subtitle">{{ 'department-tab-team.define-o-k-r-master' | translate }}</div>
        <app-department-team-new-user (choseUser)="clickedDefineOKRMaster($event)" *ngIf="canEditManagers"
                                      inputPlaceholderText="{{'department-tab-team.placeholder.okr-master' | translate}}"></app-department-team-new-user>
      </div>
      <div *ngIf="department.okrMasterId !== null">
        <div class="tab-subtitle">{{ 'department-tab-team.o-k-r-master' | translate }}</div>
        <app-user-minibutton (userDeleted)="clickedDeleteOKRMaster()" [canBeRemoved]="canEditManagers"
                             [userId]="department.okrMasterId"></app-user-minibutton>
      </div>
      <div *ngIf="topicSponsorsActivated$ | async">
        <div *ngIf="department.okrTopicSponsorId === null">
          <div class="tab-subtitle">{{ 'department-tab-team.define-topic-sponsor' | translate }}</div>
          <app-department-team-new-user (choseUser)="clickedDefineOKRTopicSponsor($event)" *ngIf="canEditManagers"
                                        inputPlaceholderText="{{'department-tab-team.placeholder.topic-sponsor' | translate}}"></app-department-team-new-user>
        </div>
        <div *ngIf="department.okrTopicSponsorId !== null">
          <div class="tab-subtitle">{{ 'department-tab-team.o-k-r-topic-sponsor' | translate }}</div>
          <app-user-minibutton (userDeleted)="clickedDeleteOKRTopicSponsor()" [canBeRemoved]="canEditManagers"
                               [userId]="department.okrTopicSponsorId"></app-user-minibutton>
        </div>
      </div>
    </div>

    <br>
    <mat-divider></mat-divider>
    <br>

    <div *ngIf="department.okrMemberIds.length === 0"
         class="tab-subtitle">{{ 'department-tab-team.no-members-defined' | translate }}</div>
    <div *ngIf="department.okrMemberIds.length !== 0"
         class="tab-subtitle">{{ 'department-tab-team.team-member' | translate }}</div>
    <div class="flex-wrap-spreadinner-nocenter">
      <div *ngFor="let currentuser of department.okrMemberIds">
        <app-user-minibutton (userDeleted)="clickedDeleteOKRMember($event)" [canBeRemoved]="canEditMembers"
                             [userId]="currentuser"></app-user-minibutton>
      </div>
      <app-department-team-new-user (choseUser)="clickedDefineOKRMember($event)" *ngIf="canEditMembers"
                                    inputPlaceholderText="{{'department-tab-team.placeholder.member' | translate}}"></app-department-team-new-user>
    </div>
  </div>
</div>
